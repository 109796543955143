import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Controller } from "react-hook-form";
import { forwardRef } from "react";
import getLabelForValue from "utils/arrays/getLabelForValue";
import { capitalCase } from "change-case";
import MDTypography from "components/MDTypography";
import { ArrowDropDown } from "@mui/icons-material";

export type SelectOptions = { label: string; value: string | number; category?: string }[];

type CustomSelectProps = {
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  selectVariant?: "standard" | "outlined" | "filled";
  data: { label: string; value: string | number }[];
  onChange?: (e: SelectChangeEvent) => void;
  control: any;
  name: string;
  disabled?: boolean;
  shrink?: boolean;
};

const CustomSelect = forwardRef(
  ({
    fullWidth,
    selectVariant,
    data,
    label,
    onChange,
    control,
    name,
    disabled = false,
    placeholder,
    shrink,
  }: CustomSelectProps) => {
    const ArrowIcon = (props) => <ArrowDropDown {...props} fontSize="medium" />;

    return (
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error }, field: { value: selected } }) => {
          return (
            <FormControl
              fullWidth={fullWidth}
              sx={
                selected || placeholder
                  ? {
                      "&": {
                        mt: "1px",
                      },
                    }
                  : {
                      "&": {
                        paddingTop: "10px",
                      },
                    }
              }
            >
              {label && (
                <InputLabel
                  shrink={
                    shrink ||
                    !!placeholder ||
                    (selected !== null && typeof selected !== "undefined" && selected !== "")
                  }
                  id={`${name}-select-label`}
                  sx={{
                    "&": {
                      marginLeft: "-12px",
                    },
                    ...(selected || placeholder
                      ? {
                          top: "8px",
                        }
                      : {}),
                  }}
                >
                  {label ? capitalCase(label) : ""}
                </InputLabel>
              )}
              <Select
                disabled={disabled}
                labelId={`${name}-select-label`}
                {...field}
                variant={selectVariant ?? "standard"}
                error={!!error}
                IconComponent={ArrowIcon}
                sx={{
                  "& > #mui-component-select-role": {
                    padding: "0 0 5px !important",
                  },
                  ...(selected || placeholder
                    ? {
                        "& > div": {
                          marginTop: "6px",
                        },
                      }
                    : {}),
                }}
                onChange={(e) => {
                  field.onChange(e);
                  onChange && onChange(e);
                }}
                data-test-id={`select-${name}`}
                data-options={data?.map((option) => option?.value).filter(Boolean) ?? []}
                defaultValue={selected}
                // value={data.length === 1 ? data[0].value : selected}
                displayEmpty={true}
                renderValue={
                  selected
                    ? undefined
                    : (value) =>
                        value ? (
                          <MenuItem>{getLabelForValue(data)(value)}</MenuItem>
                        ) : placeholder ? (
                          <MenuItem sx={{ padding: 0 }}>{placeholder}</MenuItem>
                        ) : undefined
                }
              >
                {data?.map(({ label, value }) => (
                  <MenuItem
                    data-test-id={`select-${name}-option`}
                    value={value}
                    key={value}
                    selected={value === selected}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {error && (
                <MDTypography color="error" variant="caption">
                  {error.message}
                </MDTypography>
              )}
            </FormControl>
          );
        }}
      />
    );
  }
);
CustomSelect.displayName = "CustomSelect";

export default CustomSelect;
