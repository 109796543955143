import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import MDButton from "components/MDButton";
import { Proposal, ProposalStatus } from "generated/graphql";
import useDuplicateProposal from "hooks/proposals/useDuplicateProposal";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function CreateChangeOrder({
  id,
  status,
  changeOrderJobId,
}: {
  id: Proposal["id"];
  status: Proposal["status"];
  changeOrderJobId: Proposal["changeOrderJobId"];
}) {
  const [duplicateProposal, { isSuccess, getData }] = useDuplicateProposal();
  const validStatuses = ["DRAFT", "SENT", "CLOSED_WON", "CLOSED_LOST", "CANCELED"];
  const navigate = useNavigate();

  const acceptFn = useCallback(async () => {
    const result = await duplicateProposal({
      variables: {
        id,
        isChangeOrder: true,
        changeOrderJobId,
      },
    });

    if (isSuccess(result.data)) {
      const data = getData(result);
      navigate(getRoute("proposals.draft", [["proposalId", data.newProposal.id]]));
    }
    return true;
  }, [duplicateProposal, id]);

  const cancelFn = useCallback(async () => {
    const result = await duplicateProposal({
      variables: {
        id,
        isChangeOrder: true,
        startNewProposal: true,
        changeOrderJobId,
      },
    });

    if (isSuccess(result.data)) {
      const data = getData(result);
      navigate(getRoute("proposals.draft", [["proposalId", data.newProposal.id]]));
    }
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        cancelText="Add Separate Job to Scope"
        acceptText="Make Changes to Existing Proposal"
        content="Do you want to start a new proposal for this change order or use the prior proposal as a baseline? "
      />
      <MDButton
        sx={{ minWidth: "190px" }}
        disabled={!validStatuses.includes(status)}
        variant="gradient"
        color="success"
        onClick={() => setOpen(true)}
      >
        Create Change Order
      </MDButton>
    </>
  );
}
