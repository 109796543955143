import getLocalString from "constants/Localization";
import { useGenerateUploadCompanyLogoUrlMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useGenerateUploadCompanyLogoUrl() {
  const [mutation, { loading, data, error }] = useGenerateUploadCompanyLogoUrlMutation();
  const [generateUploadCompanyLogoUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "generateUploadCompanyLogoUrl",
    },
    {
      hideMessage: true,
    }
  );
  return [generateUploadCompanyLogoUrl, context] as const;
}
