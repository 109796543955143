import { AddCircle } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { AdditionalCost, CreateAdditionalCostInput } from "generated/graphql";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import React, { useCallback, useState } from "react";
import MDAlert from "components/MDAlert";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { Box, Card, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import useCreateAdditionalCost from "hooks/organization/useCreateAdditionalCost";
import { a11yProps, TabPanel } from "components/TabPanel/TabPanel";
import AdditionalCostOptions from "modules/additionalCosts/AdditionalCostOptions";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import AdditionalCostForm from "../../../components/organization/AdditionalCostForm";
import MultiSelectExtension from "components/Shared/MultiSelectExtension/MultiSelectExtension";

export default function AddAdditionalCost({
  onAdd,
  allowCreate = true,
  isJobCosting,
}: {
  onAdd: (
    ids: string[] | number[] | AdditionalCost[],
    setEps: React.Dispatch<React.SetStateAction<any[]>>
  ) => Promise<void> | void;
  allowCreate?: boolean;
  isJobCosting?: boolean;
}) {
  const { open, onClose, onOpen } = useModalState();
  const [createAdditionalCost, { loading, error, getData }] = useCreateAdditionalCost();
  const [additionalCostId, setAdditionalCostId] = useState<string | null>(null);
  const [openTab, setOpenTab] = useState(0);
  const [additionalCosts, setAdditionalCosts] = useState([]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setOpenTab(newValue);
  };

  const onSubmit = useCallback(
    async (values: CreateAdditionalCostInput) => {
      const formattedInput = {
        ...values,
        feeAmount: +values.feeAmount.toFixed(2),
        cost: Math.floor(values.cost * 100),
      };
      const result = await createAdditionalCost({ variables: { input: formattedInput } });
      const data = getData(result);
      if (result.success) {
        onClose();
        onAdd([data.id], setAdditionalCosts);
      }
      return { success: result.success, data };
    },
    [createAdditionalCost]
  );

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <MDBox p={3}>
          {error && <MDAlert>{parseGraphQLError(error)}</MDAlert>}
          {openTab === 0 ? (
            <MDTypography variant="h6" py={3}>
              Add Additional Cost(s)
            </MDTypography>
          ) : (
            <MDTypography variant="h6" py={3}>
              Create Additional Cost to add
            </MDTypography>
          )}

          <Box sx={{ width: "100%" }}>
            {allowCreate && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={openTab}
                  onChange={handleChangeTab}
                  aria-label="add additional cost tab"
                >
                  <Tab label="Add Additional Cost" {...a11yProps(0)} />
                  <Tab label="Create Additional Cost to add" {...a11yProps(1)} />
                </Tabs>
              </Box>
            )}
            <TabPanel value={openTab} index={0}>
              <AdditionalCostOptions>
                {({ additionalCostOptions, additionalCostsById, loading, search, pagination }) => (
                  <Card>
                    <MDBox p={3}>
                      <MDBox minWidth="160px">
                        <MDTypography variant="h6">Additional Cost(s)</MDTypography>
                      </MDBox>
                      <MultiSelectExtension
                        tags={additionalCosts}
                        setTags={setAdditionalCosts}
                        options={additionalCostOptions}
                        onChange={(value) => setAdditionalCostId(value as string)}
                        loading={loading}
                        pagination={pagination}
                        search={search}
                        id={additionalCostId}
                        label="Additional Cost"
                        nameCheckFn={additionalCostsById}
                      />

                      <MDBox textAlign="right">
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            const acs = isJobCosting
                              ? additionalCosts.map((ac) => additionalCostsById[ac])
                              : additionalCosts;
                            onAdd(acs, setAdditionalCosts);
                            onClose();
                          }}
                          disabled={additionalCosts.length < 1}
                        >
                          Add
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                )}
              </AdditionalCostOptions>
            </TabPanel>
            <TabPanel value={openTab} index={1}>
              <AdditionalCostForm
                loading={loading}
                onSubmit={onSubmit}
                action={"create"}
                isOnModal
              />
            </TabPanel>
          </Box>
        </MDBox>
      </Modal>
      <MDButton variant="gradient" startIcon={<AddCircle />} onClick={onOpen}>
        Add Additional Cost
      </MDButton>
    </>
  );
}
