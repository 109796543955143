import { Proposal, ProposalStatus } from "generated/graphql";
import InfoProposalMenuItem from "./InfoProposalMenuItem";
import UpdateProposalMenuItem from "./UpdateProposalMenuItem";
import ViewProposalMenuItem from "./ViewProposalMenuItem";
import DeleteProposalMenuItem from "./DeleteProposalMenuItem";

export default function ProposalMenuItems({
  proposal,
  closeMenu,
}: {
  proposal: Proposal;
  closeMenu: () => void;
}) {
  return (
    <>
      {proposal.status === ProposalStatus.DRAFT && (
        <UpdateProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      )}

      <ViewProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      <InfoProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      {(proposal.status === ProposalStatus.DRAFT ||
        proposal.status === ProposalStatus.CANCELED) && (
        <DeleteProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      )}
    </>
  );
}
