import FormField from "components/FormField/FormField";
import { useFieldArray, useFormContext } from "react-hook-form";
import { UpdateProposalProductInputSchema } from "DDD/action-objects/StageProductUpdate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCallback, useContext, useMemo } from "react";
import {
  EquipmentPiece,
  EquipmentPieceChargeType,
  ProposalEquipmentPiece,
} from "generated/graphql";
import { Grid, Icon, IconButton, TableFooter } from "@mui/material";
import AddEquipmentCost from "modules/proposals/equipmentCosts/AddEquipmentCost";
import StageProductContext from "../StageProductContext";
import useAddProposalEquipmentPiece from "hooks/proposals/useAddProposalEquipmentPiece";
import { ProposalContext } from "modules/proposals/providers/ProposalContextProvider";
import currencyEndAdornment from "constants/currencyEndAdornment";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import timeEndAdornment from "constants/timeEndAdornment";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import TimeFormField from "components/TimeFormField/TimeFormField";
import useAddProposalEquipmentPieces from "hooks/proposals/useAddProposalEquipmentPieces";

function EquipmentCostsSubForm() {
  const { inViewMode } = useContext(ProposalContext);
  const { register, control, handleSubmit } = useFormContext<UpdateProposalProductInputSchema>();
  const { onSubmit, product } = useContext(StageProductContext);
  const { fields, remove } = useFieldArray({
    control,
    name: "equipmentPieces",
    keyName: "key",
  });

  // const [addProposalEquipmentPiece, { isSuccess }] = useAddProposalEquipmentPiece();
  const [addProposalEquipmentPieces, { isSuccess }] = useAddProposalEquipmentPieces();

  const equipmentPiecesById = useMemo(() => {
    return product.equipmentPieces.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      };
    }, {} as Record<string, ProposalEquipmentPiece | undefined>);
  }, [product.equipmentPieces]);

  const onAdd = useCallback(
    async (ids, setEps) => {
      // TODO: Find a way to update the proposal data without calling the submit
      await handleSubmit(onSubmit)();
      // First step is to add the Equipment Piece to the Proposal Product
      // Place the resulting proposal data directly in the cache
      const result = await addProposalEquipmentPieces({
        variables: {
          proposalProductId: product.id,
          equipmentPieces: ids.map((id) => ({ id, quantity: 1 })),
        },
      });

      const success = isSuccess(result.data);
      setEps([]);
      // if (success) {
      //   writeProposal(result.data.addProposalEquipmentPiece as Proposal);
      // }
    },
    [product]
  );

  // const total = equipmentPieces.reduce(
  //   (acc, val) => acc + (val.timeOnJob + val.timeTravel) * val.quantity * val.rate,
  //   0
  // );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Rate</TableCell>
                <TableCell align="center">Travel Time</TableCell>
                <TableCell align="center">On Stage Time</TableCell>
                <TableCell align="center">Total Cost</TableCell>
                {!inViewMode && <TableCell align="center"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((equipmentPiece, i) => (
                <TableRow
                  key={equipmentPiece.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, padding: "1px" }}
                >
                  <TableCell sx={{ padding: "1px" }}>
                    {equipmentPiecesById[equipmentPiece.id]?.name ?? ""}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
                    <FormField
                      placeholder={product.equipmentPieces?.[i]?.quantity?.toFixed(2)}
                      disabled={inViewMode}
                      align="right"
                      mb={0}
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": {
                            textAlign: "right",
                            padding: "1px",
                            px: "15px",
                          },
                        },
                      }}
                      {...register(`equipmentPieces.${i}.quantity`, { valueAsNumber: true })}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
                    <CurrencyFormField
                      placeholder={(product.equipmentPieces?.[i]?.rate / 100)?.toFixed(2)}
                      disabled={inViewMode}
                      align="right"
                      mb={0}
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": {
                            textAlign: "right",
                            padding: "1px",
                            px: "15px",
                          },
                        },
                      }}
                      {...currencyEndAdornment}
                      {...register(`equipmentPieces.${i}.rate`, { valueAsNumber: true })}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
                    <FormField
                      placeholder={(product.equipmentPieces?.[i]?.timeTravel / 3600)?.toFixed(2)}
                      disabled={inViewMode}
                      mb={0}
                      align="right"
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": {
                            textAlign: "right",
                            padding: "1px",
                            px: "15px",
                          },
                        },
                      }}
                      {...timeEndAdornment}
                      {...register(`equipmentPieces.${i}.timeTravel`, { valueAsNumber: true })}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
                    <FormField
                      placeholder={(product.equipmentPieces?.[i]?.timeOnjob / 3600)?.toFixed(2)}
                      disabled={inViewMode}
                      type="number"
                      align="right"
                      mb={0}
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": {
                            textAlign: "right",
                            padding: "1px",
                            px: "15px",
                          },
                        },
                      }}
                      {...timeEndAdornment}
                      {...register(`equipmentPieces.${i}.timeOnJob`, {
                        valueAsNumber: true,
                      })}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
                    {formatCentsToUSD(product.equipmentPieces?.[i]?.cost)}
                  </TableCell>
                  {!inViewMode && (
                    <TableCell align="center" sx={{ padding: "1px", px: "15px" }}>
                      <IconButton onClick={() => remove(i)}>
                        <Icon>delete</Icon>
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {!inViewMode && (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    padding: "1px",
                    px: "15px",
                  }}
                >
                  <TableCell colSpan={6} sx={{ padding: "1px", px: "15px" }}>
                    <AddEquipmentCost onAdd={onAdd} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default EquipmentCostsSubForm;
