import { InfoItem } from "components/InfoItem/InfoItem";
import { Company } from "generated/graphql";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { getCompanyInfo } from "utils/companies/getCompanyInfo";

interface CompanyInformationProps {
  company: Company;
  icon: React.ReactNode;
}

export const CompanyInformation: React.FC<CompanyInformationProps> = ({ company, icon }) => {
  const details = getCompanyInfo(company);

  return (
    <div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Company Information
        </MDTypography>
        {icon}
      </MDBox>
      <MDBox p={2}>
        <MDBox>
          {details.map((item) => (
            <InfoItem key={item.label} item={item} />
          ))}
        </MDBox>
      </MDBox>
    </div>
  );
};
