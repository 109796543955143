// @ts-nocheck

import * as L from "leaflet";
import { ColoredDivIcon, NumberedDivIcon } from "components/Map/Geoman";
import * as turf from "@turf/turf";
import cut from "./cut";
import { meterConversions } from "constants/meterConversions";

export const enableDraw = (
  map: L.Map,
  drawShape: L.PM.SUPPORTED_SHAPES,
  layerColor: string
): void => {
  map.pm.enableDraw(drawShape, {
    snappable: false,
    markerStyle: {
      icon: new ColoredDivIcon({
        color: layerColor,
      }),
    },
  });
};

export const toggleDrawMode = (
  map: L.Map,
  drawShape: L.PM.SUPPORTED_SHAPES,
  layerColor: string,
  isEditing: boolean,
  currentDrawShape: string | null
): void => {
  if (!isEditing && currentDrawShape === drawShape) {
    map.pm.disableDraw();
    return;
  }
  enableDraw(map, drawShape, layerColor);
};

export const getCenterOfLayer = (layer: L.Layer): [number, number] => {
  const layerGeojson = layer.toGeoJSON();

  if (layer instanceof L.Polygon) {
    const centroid = turf.centroid(layerGeojson);
    return [centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]];
  }

  const center = turf.center(layerGeojson);

  if (layer instanceof L.Marker) {
    center.geometry.coordinates[1] += 0.000015;
  }
  return [center.geometry.coordinates[1], center.geometry.coordinates[0]];
};

export const getLayerLabelPosition = (layer: L.Layer): [number, number] | false => {
  if (layer.feature?.properties?.labelLatLng === false) {
    return false;
  }

  if (layer.feature?.properties?.labelLatLng) {
    return layer.feature?.properties?.labelLatLng;
  }

  if (layer instanceof L.Marker) {
    return [layer.getLatLng().lat, layer.getLatLng().lng];
  }

  return getCenterOfLayer(layer);
};

export const checkForIntersects = (
  map: L.Map,
  featureGroup: L.FeatureGroup,
  newLayer: L.Layer,
  createIslandConfirmation: (callback: () => void) => boolean
): boolean => {
  let hasIntersection = false;

  const layers = featureGroup.getLayers();
  for (let i = 0, totalLayers = layers.length; i < totalLayers; i++) {
    const layer = layers[i];
    if (layer.pm?._shape !== "Polygon" || newLayer === layer) {
      continue;
    }
    if (turf.intersect(newLayer.toGeoJSON(), layer.toGeoJSON())) {
      hasIntersection = true;
      break;
    }
  }

  if (!hasIntersection) {
    return false;
  }

  return createIslandConfirmation(() => {
    cut(map as L.Map, newLayer, featureGroup.getLayers() as L.Layer[]);
  });
};

export const repositionLayerLabelLine = (
  layer: L.Layer,
  start: [number, number],
  end: [number, number]
): void => {
  layer.labelLine.setLatLngs([start, end]);
};

export const getLayerTooltipContent = (layer: L.Layer): string | null => {
  if (layer instanceof L.Polygon) {
    const area = {
      feet: turf.area(layer.toGeoJSON()) * meterConversions.squareFeet,
      yards: turf.area(layer.toGeoJSON()) * meterConversions.squareYards,
    };
    const name = layer.feature?.properties?.labelText
      ? `<strong>${layer.feature?.properties?.labelText}</strong><br />`
      : "";

    return `${name}<strong>Area:</strong> ${Math.ceil(area.feet)}ft<sup>2</sup> / ${Math.ceil(
      area.yards
    )}yd<sup>2</sup>`;
  }

  if (layer instanceof L.Polyline) {
    const latLngArray = layer.getLatLngs().map((latlng) => L.GeoJSON.latLngToCoords(latlng));

    const perimeter = {
      feet: turf.length(turf.lineString(latLngArray), {
        units: "feet",
      }),
      yards: turf.length(turf.lineString(latLngArray), {
        units: "yards",
      }),
    };
    const name = layer.feature?.properties?.labelText
      ? `<strong>${layer.feature?.properties?.labelText}</strong><br /><br />`
      : "";

    return `${name}<strong>Perimeter:</strong> ${Math.ceil(perimeter.feet)}ft / ${Math.ceil(
      perimeter.yards
    )}yd`;
  }

  if (layer instanceof L.Marker) {
    return layer.feature?.properties?.labelText;
  }

  return null;
};

export const addLayerFeature = (layer: L.Layer, color: string): void => {
  if (layer.feature) {
    return;
  }

  layer.feature = {
    properties: {
      color: color,
    },
    type: "Feature",
  };
};

export const addLayerLineLengths = (map: L.Map | undefined, layer: L.Layer): void => {
  if (!layer || layer instanceof L.Marker) {
    return;
  }

  if (layer.feature.properties.showLineLengths === false) {
    return;
  }

  layer.feature.properties.showLineLengths = true;

  const latLngs = layer.getLatLngs();
  layer.lineLengths = [];
  let coords = [];

  if (layer.pm._shape === "Polygon") {
    coords = latLngs[0];
  } else if (layer.pm._shape === "Line") {
    coords = latLngs;
  }

  for (let i = 0; i < coords.length; i++) {
    // For line shape, don't calculate the last coords
    if (layer.pm._shape === "Line" && i === coords.length - 1) {
      break;
    }

    const coord: L.LatLng = coords[i];
    const nextCoord = i + 1 == coords.length ? coords[0] : coords[i + 1];
    const startPoint = turf.point(L.GeoJSON.latLngToCoords(coord));
    const endPoint = turf.point(L.GeoJSON.latLngToCoords(nextCoord));
    const midpoint = turf.midpoint(startPoint, endPoint);
    const marker = L.marker([midpoint.geometry.coordinates[1], midpoint.geometry.coordinates[0]], {
      icon: new NumberedDivIcon({
        number: `${Math.round(
          turf.distance(startPoint, endPoint, { units: "feet" })
        )}ft/${Math.round(turf.distance(startPoint, endPoint, { units: "yards" }))}yd`,
      }),
      pmIgnore: true,
    }).addTo(map);

    marker.on("click", () => {
      layer.pm.enable();
    });

    layer.lineLengths.push(marker);
  }
};

export const addLayerLabelLine = (
  map: L.Map | undefined,
  layer: L.Layer,
  position: [number, number]
): void => {
  layer.labelLine = L.polyline([getCenterOfLayer(layer), position], {
    color: layer.feature?.properties?.color,
    pmIgnore: true,
  }).addTo(map);
};

export const removeLayerLineLabels = (map: L.Map | undefined, layer: L.Layer): void => {
  if (!layer.lineLengths) {
    return;
  }

  layer.lineLengths.forEach((lineLengthLayer) => {
    lineLengthLayer.removeFrom(map);
    lineLengthLayer.remove();
  });
  layer.lineLengths = [];
};

export const removeLayerLabels = (map: L.Map | undefined, layer: L.Layer): void => {
  if (!layer.label) {
    return;
  }

  layer.label.removeFrom(map);
  layer.label.remove();
  layer.label = null;
};

export const removeLayerLabelLine = (map: L.Map | undefined, layer: L.Layer): void => {
  if (!layer.labelLine) {
    return;
  }

  layer.labelLine.removeFrom(map);
  layer.labelLine.remove();
  layer.labelLine = null;
};

export const clearLayer = (map: L.Map | undefined, layer: L.Layer): void => {
  removeLayerLineLabels(map, layer);
  removeLayerLabels(map, layer);
  removeLayerLabelLine(map, layer);
};

export const updateDrawingPolygonToolTipContent = (e): void => {
  const latlngs = e.target._map.pm.Draw.Polygon._layer.getLatLngs();
  if (latlngs.length < 1) {
    return;
  }

  const currentPoint = turf.point(L.GeoJSON.latLngToCoords(e.latlng));
  const lastLatLng = latlngs[latlngs.length - 1];
  const lastPoint = turf.point(L.GeoJSON.latLngToCoords(lastLatLng));
  const distance = {
    feet: turf.distance(lastPoint, currentPoint, {
      units: "feet",
    }),
    yards: turf.distance(lastPoint, currentPoint, {
      units: "yards",
    }),
  };

  const latLngArray = latlngs.map((latlng) => L.GeoJSON.latLngToCoords(latlng));

  let area = { feet: 0, yards: 0 };
  if (latlngs.length > 1) {
    const positions = [...latLngArray, L.GeoJSON.latLngToCoords(e.latlng)];
    if (
      positions[positions.length - 1][0] != positions[0][0] ||
      positions[positions.length - 1][1] != positions[0][1]
    ) {
      positions.push(positions[0]);
    }

    try {
      area = {
        feet: turf.area(turf.polygon([positions])) * meterConversions.squareFeet,
        yards: turf.area(turf.polygon([positions])) * meterConversions.squareYards,
      };
    } catch (e) {}
  }

  const perimeter = {
    feet: turf.length(turf.lineString([...latLngArray, L.GeoJSON.latLngToCoords(e.latlng)]), {
      units: "feet",
    }),
    yards: turf.length(turf.lineString([...latLngArray, L.GeoJSON.latLngToCoords(e.latlng)]), {
      units: "yards",
    }),
  };

  const bearing = turf.bearing(lastPoint, currentPoint);

  e.target._map.pm.Draw.Polygon._hintMarker.setTooltipContent(
    `<strong>Segment Length: </strong>${Math.ceil(distance.feet)}ft / ${Math.ceil(distance.yards)}yd
    <br /><strong>Perimeter: </strong>${Math.ceil(perimeter.feet)}ft / ${Math.ceil(
      perimeter.yards
    )}yd
    <br /><strong>Bearing:</strong> ${Math.round(
      bearing
    )}deg<br /><strong>Area:</strong> ${Math.ceil(area.feet)}ft<sup>2</sup> / ${Math.ceil(
      area.yards
    )}yd<sup>2</sup>`
  );
  e.target._map.pm.Draw.Polygon._hintMarker.openTooltip();
};

export const updateDrawingLineToolTipContent = (e): void => {
  const latlngs = e.target._map.pm.Draw.Line._layer.getLatLngs();
  if (latlngs.length < 1) {
    return;
  }

  const currentPoint = turf.point(L.GeoJSON.latLngToCoords(e.latlng));
  const lastLatLng = latlngs[latlngs.length - 1];
  const lastPoint = turf.point(L.GeoJSON.latLngToCoords(lastLatLng));
  const distance = {
    feet: turf.distance(lastPoint, currentPoint, {
      units: "feet",
    }),
    yards: turf.distance(lastPoint, currentPoint, {
      units: "yards",
    }),
  };

  const latLngArray = latlngs.map((latlng) => L.GeoJSON.latLngToCoords(latlng));

  const perimeter = {
    feet: turf.length(turf.lineString([...latLngArray, L.GeoJSON.latLngToCoords(e.latlng)]), {
      units: "feet",
    }),
    yards: turf.length(turf.lineString([...latLngArray, L.GeoJSON.latLngToCoords(e.latlng)]), {
      units: "yards",
    }),
  };

  const bearing = turf.bearing(lastPoint, currentPoint);
  e.target._map.pm.Draw.Line._hintMarker.setTooltipContent(
    `<strong>Segment Length: </strong>${Math.ceil(distance.feet)}ft / ${Math.ceil(
      distance.yards
    )}yd<br /><strong>Perimeter: </strong>${Math.ceil(perimeter.feet)}ft / ${Math.ceil(
      perimeter.yards
    )}yd<br /><strong>Bearing:</strong> ${Math.round(bearing)}deg`
  );
  e.target._map.pm.Draw.Line._hintMarker.openTooltip();
};

export const calculateDraggedMarkerLabelPosition = (
  layer: L.Layer,
  newLatlng: L.LatLng
): L.LatLng => {
  const previousCoords = turf.point(L.GeoJSON.latLngToCoords(layer._previousLatlng));
  const newCoords = turf.point(L.GeoJSON.latLngToCoords(newLatlng));
  const markerMoveDistance = {
    kilometers: turf.distance(previousCoords, newCoords, {
      units: "kilometers",
    }),
    bearing: turf.bearing(previousCoords, newCoords),
  };
  return L.GeometryUtil.destination(
    layer.feature.properties.labelLatLng,
    markerMoveDistance.bearing,
    markerMoveDistance.kilometers * 1000
  );
};

export const getAddressObject = (
  address_components: any
): {
  home: string;
  postal_code: string;
  street: string;
  region: string;
  city: string;
  country: string;
} => {
  const shouldBeComponent = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route", "point_of_interest", "transit_station", "plus_code"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
      "administrative_area_level_6",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  const address = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: "",
  };
  address_components.forEach((component) => {
    for (const shouldBe in shouldBeComponent) {
      if (shouldBeComponent[shouldBe]?.some((x) => component.types.some((y) => y === x))) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
};

export const getPolygonFromAddress = (address: Address): Promise => {
  const addressString = `"${address.line1}, ${address.city}, ${address.state}, ${address.zip}, ${address.country}"`;
  const url = `https://nominatim.openstreetmap.org/search/${encodeURIComponent(
    addressString
  )}?format=geojson&addressdetails=1&limit=1&polygon_geojson=1`;

  return fetch(url);
};
