import { useState } from "react";
import { FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import PlantOptions from "modules/plants/PlantOptions";
import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useContext } from "react";
import useUpdateProposalPlantProduct from "hooks/proposals/useUpdateProposalPlantProduct";

import StageProductContext from "./forms/StageProductContext";
// import TransferTrucksSubForm from "./forms/subforms/TransferTrucksSubForm";
import useProposalProductDetails from "hooks/products/useProposalProductDetails";
import pluralMeasurementMaterial from "constants/pluralMeasurementMaterial";
import ProposalContext from "./providers/ProposalContextProvider";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";
import { ProductType, UpdateProposalProductInput } from "generated/graphql";
import { snakeCase } from "change-case";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import TruckingTableSubForm from "./forms/subforms/TruckingTableSubForm";
import timeEndAdornment from "constants/timeEndAdornment";
import minuteEndAdornment from "constants/minuteEndAdornment";
import { convertHrsToMins } from "DDD/action-objects/StageProductUpdate";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";

function MaterialCosts() {
  const { inViewMode } = useContext(ProposalContext);
  const { control, register, reset, handleSubmit } = useFormContext();
  const { product, defaultValues, onSubmit } = useContext(StageProductContext);
  const [updateProposalPlantProduct] = useUpdateProposalPlantProduct();
  const [selectedPlantId, setSelectedPlantId] = useState<string>(
    product?.plantProduct?.plant?.plantId
  );

  const { requiresPlant, useWeightToCalculateTransferTruckCost, requiresTrucking } =
    useProposalProductDetails(product);

  const handlePlantProductChange = useCallback(
    async (plantProductId: string) => {
      await handleSubmit(onSubmit)();
      await updateProposalPlantProduct({
        variables: {
          plantProductId,
          proposalProductId: product.id,
        },
      });
    },
    [reset, product.id]
  );

  return (
    <Grid spacing={1} container py={3}>
      <Grid className="table-form-field-inline-content">
        {requiresTrucking && (
          <Grid className="table-form-field-inline-items">
            <PlantOptions>
              {({ plantOptions, loading, pagination, search, ProductsOptionsByPlant }) => (
                <>
                  <Grid item>
                    <ScrollAsyncAutocomplete
                      variant="standard"
                      disabled={inViewMode}
                      search={search}
                      loading={loading}
                      options={plantOptions}
                      value={
                        plantOptions.find(
                          (x) => x.value == product?.plantProduct?.plant?.plantId
                        ) ?? {
                          label: "",
                          value: "",
                        }
                      }
                      onChange={(plantId: string) => {
                        setSelectedPlantId(plantId);
                      }}
                      onLoadMore={pagination.loadMore}
                      hasMore={pagination.hasNextPage}
                      label="Select Plant"
                    />
                  </Grid>
                </>
              )}
            </PlantOptions>
          </Grid>
        )}
        {requiresTrucking && (
          <Grid className="table-form-field-inline-items">
            <PlantOptions>
              {({ loading, pagination, search, ProductsOptionsByPlant }) => (
                <>
                  <ScrollAsyncAutocomplete
                    variant="standard"
                    disabled={inViewMode}
                    search={search}
                    loading={loading}
                    options={ProductsOptionsByPlant(selectedPlantId)}
                    value={
                      ProductsOptionsByPlant(selectedPlantId).find(
                        (x) => x.value == product?.plantProduct?.plantProductId
                      ) ?? { label: "", value: "" }
                    }
                    onChange={(plantProductId: string) => {
                      if (product?.plantProduct?.plantProductId !== plantProductId) {
                        handlePlantProductChange(plantProductId);
                      }
                    }}
                    onLoadMore={pagination.loadMore}
                    hasMore={pagination.hasNextPage}
                    label="Select Material"
                  />
                </>
              )}
            </PlantOptions>
          </Grid>
        )}
        {product.material.type === snakeCase(ProductType.EXCAVATE_OUT) ? (
          <Grid className="table-form-field-inline-items">
            <FormField
              disabled={inViewMode}
              label={`Material Excavated and Hauled in ${
                pluralMeasurementMaterial[product.material.measurementMaterial]
              }`}
              placeholder={Math.round(product.material.materialNeeded)}
              {...register("material.materialNeeded", { valueAsNumber: true })}
            />
          </Grid>
        ) : (
          <Grid className="table-form-field-inline-items">
            <FormField
              disabled={inViewMode}
              align="right"
              label={`Material needed in ${
                pluralMeasurementMaterial[product.material.measurementMaterial]
              }`}
              placeholder={Math.round(product.material.materialNeeded)}
              {...register("material.materialNeeded", { valueAsNumber: true })}
            />
          </Grid>
        )}
        <Grid className="table-form-field-inline-items">
          <CurrencyFormField
            disabled={inViewMode}
            label="Material Cost"
            placeholder={product.material.costMaterial / 100}
            {...currencyEndAdornment}
            control={control}
            name="material.costMaterial"
            sendEmptyValuesAsNull
          />
        </Grid>
      </Grid>
      {requiresPlant && (
        <Grid item xs={12}>
          <Controller
            name="plant.rateOnWeight"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={inViewMode}
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                        if (defaultValues.plant.rateOnWeight !== event.target.checked) {
                          // trigger submit, we need to reset the rates as they have to be null
                          // due to the change in how it's calculated
                          handleSubmit((values: UpdateProposalProductInput) => {
                            const nulledTransferTruckRatesValues = {
                              ...values,
                              transferTrucks: values.transferTrucks.map((transferTruck) => ({
                                ...transferTruck,
                                rate: null,
                              })),
                            };
                            onSubmit(nulledTransferTruckRatesValues as any);
                          })();
                        }
                      }}
                    />
                  }
                  label="Use Weight to calculate transfer truck cost"
                />
              </FormGroup>
            )}
          />
        </Grid>
      )}
      <Grid className="table-form-field-inline-content">
        {requiresPlant && (
          <>
            <Grid className="table-form-field-inline-items">
              <FormField
                disabled={inViewMode}
                label="Transfer Truck Round Trip Travel Time"
                align="right"
                placeholder={product.transferTruckRoundTripTime}
                {...timeEndAdornment}
                {...register("transferTruckRoundTripTime", { valueAsNumber: true })}
              />
            </Grid>
            <Grid className="table-form-field-inline-items">
              <FormField
                disabled={inViewMode}
                align="right"
                label="Truck Load Time Buffer"
                placeholder={convertHrsToMins(product.transferTruckLoadTimeBuffer)}
                {...minuteEndAdornment}
                {...register("transferTruckLoadTimeBuffer", { valueAsNumber: true })}
              />
            </Grid>
            <Grid className="table-form-field-inline-items">
              <FormField
                disabled={inViewMode}
                align="right"
                label="Truck Unload Time Buffer"
                placeholder={convertHrsToMins(product.transferTruckUnloadTimeBuffer)}
                {...minuteEndAdornment}
                {...register("transferTruckUnloadTimeBuffer", { valueAsNumber: true })}
              />
            </Grid>
          </>
        )}
      </Grid>
      {requiresPlant && (
        <Grid item xs={12}>
          {/* <TransferTrucksSubForm
            defaultMaterialNeeded={product.material.materialNeeded}
            costTransferTruck={+product.costTransferTruck}
          /> */}
          <TruckingTableSubForm
            defaultMaterialNeeded={product.material.materialNeeded}
            costTransferTruck={+product.costTransferTruck}
          />
        </Grid>
      )}
      {requiresTrucking && (
        <Grid item xs={12}>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography variant="body2" sx={{ fontSize: "0.8em" }}>
              Material Cost
            </MDTypography>
            <MDTypography variant="body2" sx={{ fontSize: "0.8em" }}>
              {new Intl.NumberFormat(undefined, { currency: "USD", style: "currency" }).format(
                +product.costMaterial / 100
              )}
            </MDTypography>
          </MDBox>
        </Grid>
      )}
    </Grid>
  );
}

export default MaterialCosts;
