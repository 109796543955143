import { MenuItem } from "@mui/material";
import { User } from "generated/graphql";
import { useBitumioContext } from "providers/BitumioContext";
import DeleteAccountMenuItem from "../DeleteAccountMenuItem/DeleteAccountMenuItem";
import DeleteInviteMenuItem from "../DeleteInviteMenuItem/DeleteInviteMenuItem";
import ResendInviteMenuItem from "../ResendInviteMenuItem/ResendInviteMenuItem";
import UpdateAccountMenuItem from "../UpdateAccountMenuItem/UpdateAccountMenuItem";

export default function UserMenuItems({
  status,
  id,
  closeMenu,
}: {
  status: string;
  id: User["id"];
  closeMenu: () => void;
}) {
  const isActive = status === "ACTIVE";
  const isInvited = status === "INVITED";

  // User Data required to check if the delete Account should be enabled
  const [, { isSignedInUser }] = useBitumioContext();
  const deleteDisabled = isSignedInUser({ id });

  return (
    <>
      {isActive && (
        <>
          <UpdateAccountMenuItem closeMenu={closeMenu} id={id} />
          {deleteDisabled ? (
            <MenuItem disabled>Delete Account</MenuItem>
          ) : (
            <DeleteAccountMenuItem closeMenu={closeMenu} id={id} />
          )}
        </>
      )}
      {isInvited && (
        <>
          <ResendInviteMenuItem closeMenu={closeMenu} id={id} />
          <DeleteInviteMenuItem closeMenu={closeMenu} id={id} />
        </>
      )}
    </>
  );
}
