import GlobalLoader from "components/GlobalLoader/GlobalLoader";
import { useBitumioContext } from "providers/BitumioContext";
import { useCallback } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function AuthWall({ children }: { children: JSX.Element }) {
  const [{ signedInUser, loading }] = useBitumioContext();

  if (loading) {
    return <GlobalLoader />;
  }

  if (!signedInUser) {
    const redirectTo = encodeURIComponent(
      `${document.location.pathname}${document.location.search}`.trim()
    );
    return <Navigate to={`/signin?redirectTo=${redirectTo}`} />;
  }

  return <>{children}</>;
}

export function useRedirectAfterAuthentication() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleRedirectAfterSignin = useCallback(() => {
    const redirectTo = searchParams.get("redirectTo");
    if (redirectTo) {
      navigate(redirectTo);
    } else {
      navigate(getRoute("dashboard"));
    }
  }, [searchParams]);

  return { handleRedirectAfterSignin } as const;
}
