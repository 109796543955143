import { Card } from "@mui/material";
import { CreateOpportunityInput } from "generated/graphql";
import { SubmitHandler } from "react-hook-form";
import React, { useCallback, useEffect, useRef } from "react";
import useCreateOpportunity from "../../../hooks/opportunities/useCreateOpportunity";
import MDAlert from "../../../components/MDAlert";
import parseGraphQLError from "../../../utils/graphQL/parseGraphQLError";
import { useNavigate } from "react-router";
import { getRoute } from "../../../utils/routing";
import OpportunityForm from "../../../components/opportunities/OpportunityForm/OpportunityForm";
import { useIsOpportunitySearchable } from "hooks/opportunities/useIsOpportunitySearchable";
import { useBitumioContext } from "providers/BitumioContext";

interface CreateOpportunityProps {
  onSubmit: SubmitHandler<CreateOpportunityInput>;
  loading: boolean;
}

const CreateOpportunity: React.FC = () => {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const [createOpportunity, { loading, data: createdOpportunity, isSuccess, getData, error }] =
    useCreateOpportunity();
  const [isOpportunitySearchable, { loading: opportunityLoading, data: isSearchable }] =
    useIsOpportunitySearchable();
  const navigate = useNavigate();
  const intervalRef = useRef<NodeJS.Timeout>();

  const checkIfopportunityIsSearchable = async (id: string) => {
    showLoader();
    const response = await isOpportunitySearchable(id);

    if (response.data?.isOpportunitySearchable) {
      navigate(getRoute("opportunities.list"));
      hideLoader();
      clearInterval(intervalRef.current);
    }
  };
  const initiateInterval = (id = createdOpportunity?.id) => {
    intervalRef.current = setInterval(() => {
      checkIfopportunityIsSearchable(id);
    }, 1000);
  };

  const handleSubmit = useCallback(
    async (input: CreateOpportunityInput) => {
      const result = await createOpportunity({ variables: { input } });
      initiateInterval(result.data?.createOpportunity?.id);
      const success = isSearchable && isSuccess(result.data);
      const data = getData(result);
      return { success, data };
    },
    [createOpportunity, initiateInterval, isSearchable]
  );

  const handleSuccess = useCallback(
    async (data) => {
      if (isSearchable) {
        navigate(getRoute("opportunities.list"));
      }
    },
    [navigate, isSearchable]
  );

  return (
    <>
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <Card>
        <OpportunityForm
          action="create"
          loading={false}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        />
      </Card>
    </>
  );
};

export default CreateOpportunity;
