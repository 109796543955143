import "react-phone-number-input/style.css";
import Input, { formatPhoneNumber } from "react-phone-number-input/input";
import MDInput from "components/MDInput";
import { forwardRef, useMemo, useState } from "react";
import { capitalCase } from "change-case";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Control } from "react-hook-form";
import { TextField } from "@mui/material";
interface PhoneInputProps {
  value?: string;
  variant?: string;
  onChange?: (value: string) => void;
  name: string;
  label: string;
  placeholder?: string;
  suffix?: string;
  error?: { message?: string };
  [key: string]: any;
  disabled?: boolean;
  shrink?: boolean;
  align?: string;
}

const RefPhoneInput = forwardRef((props: any, ref) => {
  const { variant, error, ...rest } = props;
  return <TextField {...rest} variant={variant} error={error} inputRef={ref} fullWidth />;
});
RefPhoneInput.displayName = "RefPhoneInput";
const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  onChange,
  name,
  align = "left",
  variant = "standard",
  error,
  ...rest
}) => {
  let formattedLabel = rest.label
    ? capitalCase(rest.label, {
        splitRegexp: /([a-z])([A-Z0-9])/g,
        stripRegexp: /[^A-Z0-9%.=\(\)\']/gi,
      })
    : "";

  if (rest.suffix) {
    formattedLabel += ` (${rest.suffix})`;
  }

  // prepend +1 for existing numbers that do not have a country code
  const modifiedValue = useMemo(() => {
    if (!value) return null;
    return value?.substring(0, 2) === "+1" ? value : `+1${value}`;
  }, [value, onChange]);

  return (
    <MDBox width="100%">
      <MDTypography variant="caption">{rest?.label}</MDTypography>
      <Input
        name={name}
        value={modifiedValue}
        onChange={onChange}
        // initialValueFormat="national"
        country="US"
        // withCountryCallingCode
        numberInputProps={{ variant, error: !!error }}
        inputComponent={RefPhoneInput}
      />
      {error && (
        <MDTypography color="error" variant="caption">
          {error?.message}
        </MDTypography>
      )}
    </MDBox>
  );
};

export default PhoneInput;
