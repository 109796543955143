import { NotificationTriggerEvent, NotificationTriggerType } from "../generated/graphql";

export const notificationTriggerEventsToTypes: {
  [key in NotificationTriggerType]: NotificationTriggerEvent[];
} = {
  OPPORTUNITY: [NotificationTriggerEvent.OPPORTUNITY_CREATED],
  PROPOSAL: [
    NotificationTriggerEvent.PROPOSAL_STARTED,
    NotificationTriggerEvent.PROPOSAL_CANCELED,
    NotificationTriggerEvent.PROPOSAL_DRAFT_NOT_SENT,
    NotificationTriggerEvent.PROPOSAL_EMAIL_CLICKED,
    NotificationTriggerEvent.PROPOSAL_EMAIL_OPENED,
    NotificationTriggerEvent.PROPOSAL_LOST,
    NotificationTriggerEvent.PROPOSAL_SENT_NOT_VIEWED,
    NotificationTriggerEvent.PROPOSAL_STATUS_CHANGED,
    NotificationTriggerEvent.PROPOSAL_VIEWED_NOT_SIGNED,
    NotificationTriggerEvent.PROPOSAL_WON,
    NotificationTriggerEvent.PROPOSAL_REASSIGNED,
    NotificationTriggerEvent.PROPOSAL_SENT,
  ],
  JOB: [
    NotificationTriggerEvent.JOB_CREATED,
    NotificationTriggerEvent.JOB_COMPLETED,
    NotificationTriggerEvent.JOB_SCHEDULED,
    NotificationTriggerEvent.JOB_RESCHEDULED,
    NotificationTriggerEvent.BEFORE_JOB_STARTS,
  ],
  JOB_PHASE: [
    NotificationTriggerEvent.BEFORE_JOB_PHASE_STARTS,
    NotificationTriggerEvent.JOB_PHASE_COMPLETED,
    NotificationTriggerEvent.JOB_PHASE_SCHEDULED,
    NotificationTriggerEvent.JOB_PHASE_RESCHEDULED,
  ],
  CONTACT: [NotificationTriggerEvent.CONTACT_CREATED, NotificationTriggerEvent.CONTACT_UPDATED],
}