import { Icon } from "@mui/material";
import AuthWall from "modules/AuthWall/AuthWall";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";
import ViewJobPage from "pages/jobs/Job";
import JobCostPage from "pages/jobs/JobCostPage";
import JobPhasesListPage from "pages/jobs/JobPhasesListPage";
import JobWorkOrderPage from "pages/jobs/JobWorkOrderPage";
import JobsListPage from "pages/jobs/JobsListPage";
import JobsSchedulePage from "pages/jobs/JobsSchedulePage/JobsSchedulePage";
import WorkOrder from "pages/jobs/WorkOrder";

const jobRoutes = [
  {
    type: "link",
    name: "Jobs",
    key: "jobs.list",
    route: "/jobs",
    icon: <Icon fontSize="medium">work</Icon>,
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <JobsListPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "link",
    name: "Job Phases",
    key: "jobs.phases",
    route: "/job-phases",
    icon: <Icon fontSize="medium">schedule</Icon>,
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <JobPhasesListPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "link",
    name: "Schedule",
    key: "jobs.schedule",
    route: "/jobs/schedule",
    icon: <Icon fontSize="medium">calendar_today</Icon>,
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <JobsSchedulePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Job",
    key: "jobs.view",
    route: "/jobs/:jobId",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <ViewJobPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Job Phase",
    key: "jobs.view.phase",
    route: "/jobs/:jobId/phases/:jobPhaseId",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <JobCostPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Work Order",
    key: "jobs.view.workorder",
    route: "/jobs/:jobId/phases/:jobPhaseId/work-order",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <WorkOrder />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Work Order",
    key: "jobs.full.view.workorder",
    route: "/jobs/:jobId/work-order",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <JobWorkOrderPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
] as const;

export default jobRoutes;
