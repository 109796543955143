import { GetJobPhaseQuery, JobPhase, useGetJobPhaseLazyQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetJobPhaseLazy() {
  const [query, { data, loading, error }] = useGetJobPhaseLazyQuery();

  const getData = ({ data }: { data?: GetJobPhaseQuery }) => {
    return data?.getJobPhase ?? null;
  };

  const getJobPhase = async (id: JobPhase["id"]) => {
    return await query({ variables: { id } });
  };

  const jobPhase = useMemo(() => {
    return (data && data.getJobPhase) || null;
  }, [data]);

  return [getJobPhase, { data, loading, error, jobPhase, getData }] as const;
}
