// react-table components
import { useFilters, useSortBy, useTable } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Pagination } from "hooks/usePaginatedResources";
import { Filtering } from "hooks/strings/useDebouncedSearchTerm";
import { useEffect, useMemo, useRef } from "react";

import { Sorting } from "hooks/strings/usePaginatedVariables";

// Declaring props types for DataTable
interface DataTableProps {
  canSearch?: boolean;
  entriesPerPageOptions?: string[];
  filtering?: Filtering;
  sorting?: Sorting;
  isSorted?: boolean;
  noEndBorder?: boolean;
  pagination?: Pagination;
  paginationProps?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  showTotalEntries?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  displayScrollTop?: boolean;
}

function DataTable({
  canSearch = false,
  entriesPerPageOptions = ["10", "25", "50", "100"],
  filtering,
  sorting,
  noEndBorder = false,
  pagination,
  paginationProps,
  showTotalEntries = true,
  table,
  isSorted,
  displayScrollTop = false,
}: DataTableProps): JSX.Element {
  const initialState =
    sorting && sorting.sortDirection && sorting.sortKey
      ? {
          sortBy: [
            {
              id: sorting.sortKey,
              desc: sorting.sortDirection === "desc",
            },
          ],
        }
      : null;
  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      ...(initialState ? { initialState } : {}),
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
    },
    useFilters,
    useSortBy
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy },
  } = tableInstance;

  const topScrollBarRef = useRef(null);
  const tableScrollBarRef = useRef(null);

  useEffect(() => {
    if (!sorting) {
      return;
    }

    if (sortBy && sortBy[0]) {
      const sortDirection =
        typeof sortBy?.[0]?.desc !== "undefined" ? (sortBy[0].desc ? "desc" : "asc") : "asc";
      const sortKey = sortBy?.[0]?.id || null;
      sorting.setSort({ sortDirection, sortKey });
    }
  }, [sortBy]);

  const {
    currentPage = 0,
    setCurrentPage = () => {},
    pageSize = 0,
    setPageSize = () => {},
    pageOptions = [],
    pages = 0,
    hasNextPage = false,
    hasPrevPage = false,
    nextPage = () => {},
    prevPage = () => {},
    total = 0,
  } = pagination ?? {};

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: number) => setPageSize(value);

  // Render the paginations
  const renderPagination =
    pageOptions?.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => setCurrentPage(Number(option))}
        active={currentPage == option}
      >
        {option}
      </MDPagination>
    )) ?? null;

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }: any) =>
    value > pageOptions.length || value < 0 ? setCurrentPage(0) : setCurrentPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions?.map((option: any) => option) ?? [];

  // Setting the entries starting point
  const entriesStart = currentPage === 1 ? (total ? 1 : 0) : pageSize * (currentPage - 1) + 1;

  // Setting the entries ending point
  let entriesEnd;
  if (currentPage === pages) {
    entriesEnd = total;
  } else if (currentPage === 1) {
    entriesEnd = pageSize;
  } else {
    entriesEnd = currentPage * pageSize;
  }

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const handleScrollTop = () => {
    const wrapperTop = document.querySelector(".wrapper-top-scroll");
    const wrapperBottom = document.querySelector(".wrapper-table");
    if (wrapperTop && wrapperBottom) {
      wrapperBottom.scrollLeft = wrapperTop.scrollLeft;
    }
  };

  const handleScrollBottom = () => {
    const wrapperTop = document.querySelector(".wrapper-top-scroll");
    const wrapperBottom = document.querySelector(".wrapper-table");
    if (wrapperTop && wrapperBottom) {
      wrapperTop.scrollLeft = wrapperBottom.scrollLeft;
    }
  };

  const adjustWidth = () => {
    if (topScrollBarRef.current && tableScrollBarRef.current) {
      const topScrollBarWidth = tableScrollBarRef.current.clientWidth;
      topScrollBarRef.current.style.width = `${topScrollBarWidth}px`;
    }
  };

  useEffect(() => {
    adjustWidth();
  }, [tableScrollBarRef]);

  return (
    <>
      {displayScrollTop && (
        <div className="wrapper-top-scroll" onScroll={handleScrollTop}>
          <div className="top-scroll-bar" ref={topScrollBarRef}></div>
        </div>
      )}
      <TableContainer
        className="wrapper-table"
        sx={{ boxShadow: "none" }}
        onScroll={handleScrollBottom}
      >
        {pageSize || canSearch ? (
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            {pageSize && (
              <MDBox display="flex" alignItems="center">
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entriesPerPageOptions}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(+newValue);
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            )}
            {canSearch && filtering && (
              <MDBox width="12rem" ml="auto">
                <MDInput
                  placeholder="Search..."
                  value={filtering.searchTerm ?? ""}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    filtering.setSearchTerm(currentTarget.value);
                  }}
                  inputRef={(input) => input && input.focus()}
                />
              </MDBox>
            )}
          </MDBox>
        ) : null}
        <Table {...getTableProps()} ref={tableScrollBarRef}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup: any) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column: any) => {
                  const sorted = setSortedValue(column);
                  const align = column.align ? column.align : "left";

                  return (
                    <DataTableHeadCell
                      {...column.getHeaderProps()}
                      key={column.getHeaderProps(column.getSortByToggleProps()).key}
                      width={column.width ? column.width : "auto"}
                      minWidth={column.minWidth ?? "auto"}
                      sorted={sorted}
                      align={align}
                    >
                      <MDBox display="flex" alignItems="center">
                        <MDBox
                          sx={{ position: "relative", flexGrow: 1 }}
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                          {sorted && !column.disableSortBy && (
                            <MDBox
                              position="absolute"
                              top={0}
                              right={align !== "right" ? "16px" : 0}
                              left={align === "right" ? "unset" : "unset"}
                              sx={({ typography: { size } }: any) => ({
                                fontSize: size.lg,
                              })}
                            >
                              <MDBox
                                position="absolute"
                                top={-6}
                                color={sorted === "asce" ? "text" : "secondary"}
                                opacity={sorted === "asce" ? 1 : 0.5}
                              >
                                <Icon>arrow_drop_up</Icon>
                              </MDBox>
                              <MDBox
                                position="absolute"
                                top={0}
                                color={sorted === "desc" ? "text" : "secondary"}
                                opacity={sorted === "desc" ? 1 : 0.5}
                              >
                                <Icon>arrow_drop_down</Icon>
                              </MDBox>
                            </MDBox>
                          )}
                        </MDBox>
                        {column.canFilter && column.Filter ? (
                          <div>{column.render("Filter")}</div>
                        ) : null}
                      </MDBox>
                    </DataTableHeadCell>
                  );
                })}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any, key: any) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                  data-test-id="record-row"
                  data-id={row.id}
                >
                  {row.cells.map((cell: any) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions?.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <>
              {showTotalEntries && pages ? (
                <MDBox mb={{ xs: 3, sm: 0 }}>
                  <MDTypography variant="button" color="secondary" fontWeight="regular">
                    Showing {entriesStart} to {entriesEnd} of {total} entries
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox mb={{ xs: 3, sm: 0 }}>
                  <MDTypography variant="button" color="secondary" fontWeight="regular">
                    Showing {rows.length ? 1 : 0} to {rows.length} of {rows.length} entries
                  </MDTypography>
                </MDBox>
              )}
            </>
          )}

          {pageOptions?.length > 1 && (
            <MDPagination
              variant={paginationProps?.variant ? paginationProps.variant : "gradient"}
              color={paginationProps?.color ? paginationProps.color : "info"}
            >
              {hasPrevPage && (
                <MDPagination item onClick={() => prevPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[currentPage - 1]}
                    onChange={(event: any) => {
                      handleInputPagination(event);
                    }}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {hasNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      </TableContainer>
    </>
  );
}

export default DataTable;
