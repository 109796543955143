import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import CreateProposal from "modules/proposals/CreateProposal";

export default function ProposalCreatePage() {
  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Create Proposal
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CreateProposal />
        </Grid>
      </Grid>
    </DashboardPage>
  );
}
