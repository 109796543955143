import { Icon } from "@mui/material";
import AuthWall from "modules/AuthWall/AuthWall";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";

import UpdateOpportunityPage from "pages/opportunities/update";
import OpportunitiesPage from "../pages/opportunities";
import CreateOpportunityPage from "../pages/opportunities/create";
import Opportunity from "../../public/assets/images/opportunities.png";

const opportunityRoutes = [
  {
    type: "link",
    name: "Opportunities",
    key: "opportunities.list",
    route: "/opportunities",
    icon: <Icon fontSize="medium">emoji_events</Icon>,
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <OpportunitiesPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Create Opportunity",
    key: "opportunities.create",
    route: "/opportunities/create",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <CreateOpportunityPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Update",
    key: "opportunities.update",
    route: "/opportunities/:opportunityId/update",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <UpdateOpportunityPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
] as const;

export default opportunityRoutes;
