import { Box, Grid, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import QuickbooksEditableInformation from "../QuickbooksEditableInformation";
import JobsDetailsSummary from "../JobsDetailsSummary";
import {
  Proposal,
  Job,
  Organization,
  SaveQuickbooksIntegrationInput,
  OrganizationLinkedServiceName,
} from "generated/graphql";
import { useState } from "react";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import useCreateJobInvoiceOnLinkedService from "hooks/organization/linkedServices/useCreateJobInvoiceOnLinkedService";
import useAddJobToQueueList from "hooks/quickbooksDesktop/useAddJobToQueueList";
import useGetOrganization from "hooks/organization/useGetOrganization";
import useSaveQuickbooksIntegration from "hooks/jobs/useSaveQuickbooksIntegration";

export default function QuickbooksConfirmationModal({
  proposalId,
  job,
  organization,
  shouldPushTaxesToQuickbooks,
  mayShowTaxNotification,
}: {
  proposalId: Proposal["id"];
  job: Job;
  organization: Organization;
  shouldPushTaxesToQuickbooks?: Boolean;
  mayShowTaxNotification?: Boolean;
}) {
  const { data } = useGetOrganization();
  const [formData, setFormData] = useState<SaveQuickbooksIntegrationInput | null>(null);
  const { show } = useGlobalMessage();
  const [addJobToQueueList] = useAddJobToQueueList();
  const [saveQuickbooksIntegration] = useSaveQuickbooksIntegration();
  const [createJobInvoiceOnLinkedService] = useCreateJobInvoiceOnLinkedService();

  const hasLinkedQuickbooksDesktop = !!data?.linkedServices?.find(
    (service) =>
      service.service === OrganizationLinkedServiceName.QUICKBOOKS_DESKTOP && service.active
  );

  const hasLinkedQuickbooksOnline = !!data?.linkedServices?.find(
    (service) => service.service === OrganizationLinkedServiceName.QUICKBOOKS && service.active
  );

  const addToQuickbooksDesktopQueue = (jobId) => {
    if (jobId) {
      addJobToQueueList(jobId);
    }
  };

  const syncQuickbooks = async (jobId) => {
    try {
      const response = await saveQuickbooksIntegration({
        variables: {
          input: {
            jobId: jobId,
            data: JSON.stringify(formData),
          },
        },
      });

      if (response?.data?.saveQuickbooksIntegration) {
        if (mayShowTaxNotification && !shouldPushTaxesToQuickbooks) {
          show({
            message:
              "Taxes will not be added to the Quickbooks invoice because more than one tax rate is associated to the proposal or none at all. Please apply taxes to the invoice within Quickbooks",
            options: { variant: "info", autoHideDuration: 10000 },
          });
        }

        if (hasLinkedQuickbooksDesktop) {
          addToQuickbooksDesktopQueue(jobId);
        }

        if (hasLinkedQuickbooksOnline) {
          createJobInvoiceOnLinkedService({
            variables: {
              jobId,
              service: OrganizationLinkedServiceName.QUICKBOOKS,
            },
          });
        }
      } else {
        show({
          message: "There was an error saving the data, please try again",
          options: { variant: "error", autoHideDuration: 10000 },
        });
      }
    } catch (error) {
      show({
        message: "There was an error syncing with Quickbooks, please try again",
        options: { variant: "error", autoHideDuration: 10000 },
      });
    }
  };

  const validateFormData = () => {
    if (!formData) return false;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.customerEmail)) {
      show({
        message: "Please enter a valid email address.",
        options: { variant: "error", autoHideDuration: 10000 },
      });
      return false;
    }

    const numberRegex = /^[0-9]*$/;
    if (
      !numberRegex.test(formData.customerBillingAddressZip) ||
      !numberRegex.test(formData.shipAddressAddressZip)
    ) {
      show({
        message: `${
          !numberRegex.test(formData.customerBillingAddressZip) ? "Address Zip" : "Ship Address Zip"
        } should contain only numbers.`,
        options: { variant: "error", autoHideDuration: 10000 },
      });
      return false;
    }
    return true;
  };

  return (
    <Box style={{ padding: "2em 0.5em" }}>
      <Typography variant="h4" align="center">
        Confirm Quickbooks Details
      </Typography>
      <Grid display={{ xs: "block", md: "flex" }}>
        <Grid md={7} xs={12} style={{ padding: "1em" }}>
          <QuickbooksEditableInformation
            proposalId={proposalId}
            job={job}
            organization={organization}
            onChange={(data) => setFormData(data)}
          />
        </Grid>
        <Grid md={5} xs={12} style={{ padding: "1em" }}>
          <JobsDetailsSummary proposalId={proposalId} job={job} />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
        <MDButton
          color="success"
          sx={{ mr: "30em", mb: "10px" }}
          onClick={() => {
            if (validateFormData()) {
              syncQuickbooks(job?.id);
            }
          }}
        >
          Confirm
        </MDButton>
      </Box>
    </Box>
  );
}
