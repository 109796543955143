import { capitalCase } from "change-case";
import { sortDirectionMap } from "constants/sortDirectionMap";
import {
  GetJobsQueryVariables,
  GetJobsSortKey,
  JobStatus,
  SortDirection,
  JobBillingStatus,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import JobsActionsCell from "modules/jobs/ActionsCell";
import { useCallback, useMemo, useEffect } from "react";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { useGetJobs } from "./useGetJobs";
import { Link } from "react-router-dom";
import useGetAllCompanies from "hooks/companies/useGetAllCompanies";
import MultipleChoiceFilter from "components/filters/MultipleChoiceFilter/MultipleChoiceFilter";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { enumToValueAsKeyOptions } from "utils/enums/enumToValueAsKeyOptions";
import useJobsBillingStatuses from "hooks/jobs/useJobsBillingStatuses";

export const jobsSortKeyMap = {
  id: GetJobsSortKey.ID,
  "company.name": GetJobsSortKey.COMPANY,
  status: GetJobsSortKey.STATUS,
  createdAt: GetJobsSortKey.START,
};

export const jobsInitialSortDirectionKey = "desc";
export const jobsInitialSortDirectionValue = SortDirection.DESC;
export const jobsInitialSortKeyKey = "id";
export const jobsInitialSortKeyValue = GetJobsSortKey.ID;

export default function useJobsTable(variables: Partial<GetJobsQueryVariables>) {
  let savedFilters = JSON.parse(localStorage.getItem("useJobsTableFilters")) ?? null;
  if (variables?.companyId) {
    savedFilters = {};
  }

  const { filtering, offset, sorting } = usePaginatedVariables({
    initialSortDirection: jobsInitialSortDirectionKey,
    initialSortKey: jobsInitialSortKeyKey,
    initialStatuses: savedFilters?.statuses,
    initialMultipleCompanyIds: savedFilters?.multipleCompanyIDs,
    initialSearchTerm: savedFilters?.searchTerm ?? null,
    initialBillingStatuses: savedFilters?.billingStatuses,
  });

  const {
    loading: companiesLoading,
    data: companiesData,
    error: companiesError,
  } = useGetAllCompanies();

  const { data, loading, error, paginatorInfo } = useGetJobs({
    variables: {
      page: offset.page,
      first: offset.first,
      search: filtering.debouncedSearchTerm,
      sortKey: jobsSortKeyMap[sorting.sortKey] || jobsInitialSortKeyValue,
      sortDirection: sortDirectionMap[sorting.sortDirection] || jobsInitialSortDirectionValue,
      statuses: filtering.statuses as JobStatus[],
      billingStatuses: filtering.billingStatuses as JobBillingStatus[],
      companyId: filtering.companyId ?? variables.companyId,
      multipleCompanyIDs: filtering.multipleCompanyIDs ?? variables.multipleCompanyIDs,
    },
  });

  const { data: enumBillingStatus } = useJobsBillingStatuses();

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const handleStatusChecked = useCallback(
    (selectedOptions) => {
      return filtering.setStatuses(selectedOptions);
    },
    [filtering]
  );

  const statusFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <MultipleChoiceFilter
          id={id}
          value={filtering.statuses}
          options={enumToValueOptions(JobStatus)}
          onChange={handleStatusChecked}
        />
      );
    },
    [filtering.statuses]
  );

  const handleCompanyChecked = useCallback(
    (selectedOptions) => {
      return filtering.setMultipleCompanyIds(selectedOptions);
    },
    [filtering]
  );

  useEffect(() => {
    localStorage.setItem("useJobsTableFilters", JSON.stringify(filtering));
  }, [filtering]);

  const companyFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <MultipleChoiceFilter
          id={id}
          value={filtering.multipleCompanyIDs}
          loading={companiesLoading}
          error={companiesError}
          options={
            companiesData?.map((company) => ({ label: company.name, value: company.id })) ?? []
          }
          onChange={handleCompanyChecked}
        />
      );
    },
    [
      filtering.companyId,
      filtering.multipleCompanyIDs,
      companiesData,
      companiesLoading,
      companiesError,
    ]
  );

  const handleBillingStatusChecked = useCallback(
    (selectedOptions) => {
      return filtering.setBillingStatuses(selectedOptions);
    },
    [filtering]
  );

  const billingStatusesFilter = ({ column: { id } }) => {
    return (
      <MultipleChoiceFilter
        id={id}
        value={filtering.billingStatuses}
        options={enumToValueAsKeyOptions(enumBillingStatus)}
        onChange={handleBillingStatusChecked}
      />
    );
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row, value }) => (
          <Link style={{ color: "inherit" }} to={`/jobs/${value}`}>
            {row.original.externalId}
          </Link>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => capitalCase(value),
        Filter: statusFilter,
      },
      { Header: "Company Name", accessor: "company.name", Filter: companyFilter },
      { Header: "Contact Name", accessor: "contact.firstName", disableSortBy: true },
      { Header: "Address", accessor: "address.line1", disableSortBy: true },
      { Header: "Phase Name", accessor: "proposalStage.name", disableSortBy: true },
      {
        Header: "Billing Status",
        accessor: "billingStatus",
        Cell: ({ value }) => (value ? capitalCase(value) : ""),
        Filter: billingStatusesFilter,
      },
      {
        Header: "Job Total",
        accessor: "proposedOverallTotal",
        align: "right",
        disableSortBy: true,
        Cell: (props) => formatCentsToUSD(props.value),
      },
      {
        Header: "Actions",
        accessor: "actions",
        align: "right",
        disableSortBy: true,
        Cell: JobsActionsCell,
      },
    ];
  }, [statusFilter, companyFilter, billingStatusesFilter]);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
    sorting,
  } as const;
}
