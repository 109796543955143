import { Card, Grid, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable/DataTable";
import { JobPhase, JobPhaseStatus, JobStatus } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { getRoute } from "utils/routing";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { sentenceCase } from "change-case";

export default function ProductSummarySection({ phases }: { phases: JobPhase[] }) {
  const { jobId } = useParams();
  const columns = [
    {
      Header: "Phase",
      accessor: "proposalProduct.material.name",
      width: "20%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "10%",
      Cell: (props) => <>{sentenceCase(props.value)}</>,
    },
    {
      Header: "Measurement Size",
      accessor: "jobSize",
      width: "20%",
    },
    {
      Header: "Total",
      accessor: "proposedOverallTotal",
      width: "20%",
      Cell: (props) => (
        <>
          <div>Proposed &nbsp;&nbsp;&nbsp; {formatCentsToUSD(props.value)}</div>
          <div>
            Actual &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            {formatCentsToUSD(props.row.original.actualOverallTotal)}
          </div>
        </>
      ),
    },
    {
      Header: "Start Date",
      accessor: "startsAt",
      width: "20%",
      Cell: (props) =>
        props.row.original.status === JobStatus.TO_BE_SCHEDULED ? (
          <Link to={`/jobs/schedule?jobId=${jobId}`}>Add to schedule</Link>
        ) : (
          <>{props.value}</>
        ),
    },
    {
      Header: "Job Hours",
      accessor: "jobDuration",
      width: "10%",
    },
    {
      Header: "",
      accessor: "id",
      width: "10%",
      Cell: (props) => (
        <>
          <MenuItem
            component={Link}
            target="_blank"
            rel="noreferrer"
            to={getRoute("jobs.view.workorder", [
              ["jobId", jobId],
              ["jobPhaseId", props.value],
            ])}
          >
            View Work Order
          </MenuItem>
          {[
            JobPhaseStatus.TO_BE_SCHEDULED,
            JobPhaseStatus.SCHEDULED,
            JobPhaseStatus.IN_PROGRESS,
            JobPhaseStatus.JOB_COSTING,
            JobPhaseStatus.COMPLETE,
          ].includes(props.row.original.status) && (
            <MenuItem
              component={Link}
              to={getRoute("jobs.view.phase", [
                ["jobId", jobId],
                ["jobPhaseId", props.value],
              ])}
            >
              Input Job Costs
            </MenuItem>
          )}
        </>
      ),
    },
  ];
  const tableData = { columns, rows: phases };

  return (
    <Card>
      <MDBox mt={4} p={4}>
        <MDTypography>Product Summary</MDTypography>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <DataTable table={tableData} showTotalEntries={false} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
