import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Switch,
  Checkbox,
} from "@mui/material";
import CountrySelect from "components/CountrySelect/CountrySelect";
import FormField from "components/FormField/FormField";
import LogoUpload from "components/LogoUpload/LogoUpload";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PhoneInput from "components/PhoneInput/PhoneInput";
import StateSelect from "components/StateSelect/StateSelect";
import {
  schema,
  defaultValues,
  defaultValuesSecondaryContact,
} from "DDD/action-objects/CompanyCreate";
import { CreateCompanyContactInput, CreateCompanyInput, NotificationTriggerType, } from "generated/graphql";
import { SubmitHandler, useFieldArray, useForm, Controller } from "react-hook-form";
interface CreateCompanyProps {
  onSubmit: SubmitHandler<CreateCompanyInput>;
  loading: boolean;
}

export default function CreateCompany({ onSubmit, loading }: CreateCompanyProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
    control,
    setValue,
    getValues,
    trigger,
  } = useForm<CreateCompanyInput>({
    resolver: zodResolver(schema),
    // @ts-ignore
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts.create",
  });

  const notificationTypes = Object.values(NotificationTriggerType);

  const watchLogo = watch("logo");
  const handleCopyInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const contactValues = getValues("contacts.create.0");
    if (checked) {
      setValue("contacts.create.0", {
        ...contactValues,
        phone: getValues("phone"),
        email: getValues("email"),
      } as CreateCompanyContactInput);
    }
  };

  const handleNotificationChange = (name, checked) => {
    const notifications = getValues('notificationTypes');
    const newNotifications = { ...notifications, [name]: checked };
    setValue('notificationTypes', newNotifications);
    trigger('notificationTypes');
  };

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async ({ logo, ...rest }: CreateCompanyInput) => {
          const result = await onSubmit({ ...rest, logo });
          // @ts-expect-error: FIX update types
          if (result.success) {
            reset();
          }
        })}
      >
        <MDTypography variant="h5">Company Information</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Website"
                placeholder="https://google.com"
                error={errors.website}
                {...register("website")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Email"
                placeholder="Email"
                error={errors.email}
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => <PhoneInput {...field} label="Phone" error={errors.phone} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Line 1"
                placeholder="Address Line 1"
                error={errors.addressLine1}
                {...register("addressLine1")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Line 2"
                placeholder="Address Line 2"
                error={errors.addressLine2}
                {...register("addressLine2")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address City"
                placeholder="Address City"
                error={errors.addressCity}
                {...register("addressCity")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StateSelect
                name="addressState"
                label="Address State"
                countryFieldName="addressCountry"
                control={control}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Zip"
                placeholder="Address Zip"
                error={errors.addressZip}
                {...register("addressZip")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CountrySelect
                name="addressCountry"
                label="Address Country"
                defaultValue="US"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="logo"
                render={({ field }) => (
                  <LogoUpload
                    sendPath={(path) => field.onChange(path)}
                    label="Logo"
                    watcher={watchLogo}
                    error={errors.logo}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h5">Notification Settings</MDTypography>
              <MDTypography variant="h6" style={{ fontWeight: 'normal' }}>
                What type of notifications do you want to send to this contact?
              </MDTypography>
              <MDBox display="flex" gap={2} mt={2}>
                {notificationTypes.map((type) => {
                  return (
                    <FormControlLabel
                      key={type}
                      control={
                        <Checkbox
                          name={type.toLowerCase()}
                          {...register(`notificationTypes.${type}`)}
                          checked={getValues(`notificationTypes.${type}`)}
                          onChange={(event) => handleNotificationChange(type, event.target.checked)}
                        />
                      }
                      label={type.replace('_', ' ').toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}
                    />
                  );
                })}
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  {fields.map((field, index) => (
                    <Grid container spacing={3} key={field.id} mt={index === 0 ? 0 : 2}>
                      <Grid item xs={12} display="flex" alignItems="center">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          gap="2"
                          width="100%"
                          alignItems="center"
                        >
                          <MDTypography variant="h6">
                            {index === 0
                              ? "Primary Contact (Required)"
                              : `Other Contact (${index})`}
                          </MDTypography>
                          <FormControlLabel
                            sx={{ display: "flex", alignItems: "center" }}
                            control={<Checkbox />}
                            onChange={handleCopyInfo}
                            label="Copy Company Info to Contact"
                          />
                        </Box>
                        {index > 0 ? (
                          <IconButton
                            //@ts-ignore
                            color="negative"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <Icon fontSize="large" color="inherit">
                              delete
                            </Icon>
                          </IconButton>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          placeholder="Title"
                          defaultValue={field.title}
                          error={errors.contacts?.create?.[index]?.title}
                          {...register(`contacts.create.${index}.title` as const)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          placeholder="First Name"
                          defaultValue={field.firstName}
                          error={errors.contacts?.create?.[index]?.firstName}
                          {...register(`contacts.create.${index}.firstName` as const)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          placeholder="Last Name"
                          defaultValue={field.lastName}
                          error={errors.contacts?.create?.[index]?.lastName}
                          {...register(`contacts.create.${index}.lastName` as const)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          placeholder="Role"
                          defaultValue={field.role}
                          error={errors.contacts?.create?.[index]?.role}
                          {...register(`contacts.create.${index}.role` as const)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          control={control}
                          name={`contacts.create.${index}.phone` as const}
                          render={({ field }) => (
                            <PhoneInput
                              {...field}
                              label="Phone"
                              error={errors.contacts?.create?.[index]?.phone}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          placeholder="Email"
                          defaultValue={field.email}
                          error={errors.contacts?.create?.[index]?.email}
                          {...register(`contacts.create.${index}.email` as const)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name={`contacts.create.${index}.SMSNotifications` as const}
                          control={control}
                          render={({ field }) => (
                            <>
                              <MDTypography variant="caption">SMS Notifications</MDTypography>
                              <Switch {...field} checked={field?.value} />
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <MDButton
                type="button"
                variant="gradient"
                color="secondary"
                onClick={() => {
                  append(defaultValuesSecondaryContact);
                }}
              >
                Add Contact
              </MDButton>
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
