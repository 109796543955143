import { useState, useCallback } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import {
  Proposal,
  ProposalLayout,
  ProposalSource,
  ProposalStatus,
  UpdateProposalInfoInput,
  ProposalProduct,
  UserRole,
} from "generated/graphql";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { Person, getFullName } from "utils/people/getFullName";
import CancelAndDuplicateProposal from "../buttons/CancelAndDuplicateProposal/CancelAndDuplicateProposal";
import CancelProposal from "../buttons/CancelProposal/CancelProposal";
import MoveProposalToClosed from "../buttons/MoveProposalToClosed/MoveProposalToClosed";
import MoveProposalToClosedWon from "../buttons/MoveProposalToClosedWon/MoveProposalToClosedWon";
import MoveProposalToDraft from "../buttons/MoveProposalToDraft/MoveProposalToDraft";
import ProposalCompanyContactsOptions from "../ProposalCompanyContactsOptions/ProposalCompanyContactsOptions";
import DuplicateProposal from "../buttons/DuplicateProposal/DuplicateProposal";
import ViewProposalButton from "../buttons/ViewProposal/ViewProposal";
import ViewRawProposalButton from "../buttons/ViewRawProposal/ViewRawProposal";
import ProposalStageSummary, { DefinedColumns } from "modules/proposals/ProposalStageSummary";
import ProposalContext from "modules/proposals/providers/ProposalContextProvider";
import ProposalFormsContextProvider from "modules/proposals/providers/ProposalFormsContextProvider";
import { useApolloClient } from "@apollo/client";
import proposalProductFragment from "schema/fragments/proposalProductFragment";
import { useCustomGetProposalQuery } from "generated/graphql";

const getDefaultValues = ({
  id,
  userId,
  contactId,
  layout,
  source,
  proposalNotes,
  removeUnitPriceAndQuantity,
  removeIndividualLineItemPricing,
}: Proposal): UpdateProposalInfoInput => ({
  id,
  userId,
  contactId,
  proposalNotes,
  layout,
  source,
  removeUnitPriceAndQuantity,
  removeIndividualLineItemPricing,
});

export default function ProposalInfoForm({
  proposal,
  onSubmit,
  loading,
}: {
  proposal: Proposal;
  onSubmit: SubmitHandler<UpdateProposalInfoInput>;
  loading: boolean;
}) {
  const methods = useForm<UpdateProposalInfoInput>({
    defaultValues: getDefaultValues(proposal),
  });

  const disabled = loading || ["CLOSED_WON", "CLOSED_LOST", "CANCELED"].includes(proposal.status);
  const client = useApolloClient();
  const showMarkAsClosedWon = !["CLOSED_WON", "CLOSED_LOST"].includes(proposal.status);
  const [contact, setContact] = useState<string | null>(null);

  const { data } = useCustomGetProposalQuery({
    variables: { id: proposal.id },
  });

  const writeProposalProduct = useCallback(
    (proposalProduct: ProposalProduct) => {
      client.writeFragment({
        id: `ProposalProduct:${proposalProduct.id}`,
        fragment: proposalProductFragment,
        data: proposalProduct,
      });
    },
    [client]
  );

  return (
    <ProposalFormsContextProvider>
      <ProposalContext.Provider
        value={{
          proposal: proposal as Proposal,
          writeProposalProduct,
          inViewMode: false,
        }}
      >
        <MDBox component="form" role="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} mb={3}>
                  <MDBox display="flex" flexWrap="wrap" justifyContent="start">
                    <MDBox sx={{ textAlign: "center", minWidth: "90px" }}>
                      <MoveProposalToDraft id={proposal.id} status={proposal.status} />
                    </MDBox>
                    {showMarkAsClosedWon && (
                      <MDBox sx={{ textAlign: "center", minWidth: "90px" }}>
                        <MoveProposalToClosedWon id={proposal.id} />
                      </MDBox>
                    )}
                    <MDBox sx={{ textAlign: "center", minidth: "90px" }}>
                      <MoveProposalToClosed id={proposal.id} status={proposal.status} />
                    </MDBox>
                    <MDBox sx={{ textAlign: "center", minWidth: "90px" }}>
                      <CancelProposal id={proposal.id} status={proposal.status} />
                    </MDBox>
                    <MDBox sx={{ textAlign: "center", minWidth: "90px" }}>
                      <DuplicateProposal id={proposal.id} status={proposal.status} />
                    </MDBox>
                    <MDBox sx={{ textAlign: "center", minWidth: "90px" }}>
                      <CancelAndDuplicateProposal id={proposal.id} status={proposal.status} />
                    </MDBox>
                    <MDBox sx={{ textAlign: "center", minWidth: "90px" }}>
                      <ViewProposalButton proposal={proposal} />
                    </MDBox>
                    <MDBox sx={{ textAlign: "center", minWidth: "90px" }}>
                      <ViewRawProposalButton proposal={proposal} />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6} sx={{ padding: "16px" }}>
              <MDBox
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                sx={{ marginBottom: "1em" }}
              >
                <Grid item xs={12} sm={4}>
                  <MDBox flexBasis="auto">
                    <MDTypography variant="h5">Status</MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <MDBox
                    display="flex"
                    flexWrap="nowrap"
                    gap={2}
                    flexBasis="auto"
                    alignItems="center"
                  >
                    <MDBox
                      sx={{
                        display: "flex",
                        border: "1px solid #cfcfcf",
                        borderRadius: "4px",
                        justifyContent: "center",
                        padding: "4px 32px",
                        width: "fit-content",
                      }}
                    >
                      <MDTypography variant="h5">{proposal.status}</MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDTypography variant="h5">Salesperson:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <OrganizationUsersOptions
                        selected={{
                          label: proposal?.user ? getFullName(proposal.user) : "",
                          value: proposal.user?.id,
                        }}
                        queryOverride={{
                          first: 1000,
                          roles: [UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.SALES_PERSON],
                        }}
                      >
                        {({ organizationUsersOptions, loading, search }) => (
                          <Controller
                            control={methods.control}
                            name="userId"
                            render={({ field, fieldState: { error } }) => (
                              <AutoComplete
                                options={organizationUsersOptions}
                                field={field}
                                loading={loading}
                                error={error?.message}
                                search={search}
                                disabled={disabled}
                              />
                            )}
                          />
                        )}
                      </OrganizationUsersOptions>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDTypography variant="h5">Company Name:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography
                        variant="body2"
                        style={{ borderBottom: "1px solid rgba(0,0,0,.2)" }}
                      >
                        {data?.getProposal?.company?.name}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDTypography variant="h5">Contact:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <ProposalCompanyContactsOptions proposalId={proposal.id}>
                        {({ proposalCompanyContactsOptions, loading }) => (
                          <Controller
                            control={methods.control}
                            name="contactId"
                            render={({ field, fieldState: { error } }) =>
                              loading ? (
                                <CircularProgress />
                              ) : (
                                <AutoComplete
                                  options={proposalCompanyContactsOptions}
                                  field={{
                                    onChange: (selectedValue) => {
                                      setContact(selectedValue);
                                      field.onChange(selectedValue);
                                    },
                                    value: field.value,
                                    onBlur: () => {},
                                    name: field.name,
                                    ref: field.ref,
                                  }}
                                  loading={loading}
                                  error={error?.message}
                                  disabled={disabled}
                                />
                              )
                            }
                          />
                        )}
                      </ProposalCompanyContactsOptions>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDTypography variant="h5">Contact Phone:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <ProposalCompanyContactsOptions proposalId={proposal.id}>
                        {({ contactsData, loading }) => (
                          <Controller
                            control={methods.control}
                            name="contactId"
                            render={({ field, fieldState: { error } }) =>
                              loading ? (
                                <CircularProgress />
                              ) : (
                                <MDTypography
                                  variant="body2"
                                  style={{
                                    borderBottom: "1px solid rgba(0,0,0,.2)",
                                    display: "flex",
                                    minHeight: "24px",
                                  }}
                                >
                                  {contact
                                    ? contactsData.find((contactData) => contactData.id === contact)
                                        ?.phone
                                    : contactsData.length > 0
                                    ? contactsData[0].phone
                                    : ""}
                                </MDTypography>
                              )
                            }
                          />
                        )}
                      </ProposalCompanyContactsOptions>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDTypography variant="h5">Contact Email:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <ProposalCompanyContactsOptions proposalId={proposal.id}>
                        {({ contactsData, loading }) => (
                          <Controller
                            control={methods.control}
                            name="contactId"
                            render={({ field, fieldState: { error } }) =>
                              loading ? (
                                <CircularProgress />
                              ) : (
                                <MDTypography
                                  variant="body2"
                                  style={{ borderBottom: "1px solid rgba(0,0,0,.2)" }}
                                >
                                  {" "}
                                  {contact
                                    ? contactsData.find((contactData) => contactData.id === contact)
                                        ?.email
                                    : contactsData.length > 0
                                    ? contactsData[0].email
                                    : ""}{" "}
                                </MDTypography>
                              )
                            }
                          />
                        )}
                      </ProposalCompanyContactsOptions>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDTypography variant="h5">Source:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomSelect
                        fullWidth
                        control={methods.control}
                        name="source"
                        data={enumToValueOptions(ProposalSource)}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDTypography variant="h5">Layout:</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomSelect
                        fullWidth
                        control={methods.control}
                        name="layout"
                        data={enumToValueOptions(ProposalLayout)}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="removeUnitPriceAndQuantity"
                        control={methods.control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label="Remove Unit Price And Quantity"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="removeIndividualLineItemPricing"
                        control={methods.control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label="Remove Individual Line Item Pricing"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={3}>
                  <MDBox display="flex" alignItems="start" height="100%">
                    <MDTypography variant="h5">Proposal Notes:</MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextareaAutosize
                    {...methods.register("proposalNotes")}
                    disabled={disabled}
                    minRows={15}
                    style={{ width: "100%", borderRadius: "10px", border: "1px solid #ccc" }}
                  />
                </Grid>
              </Grid>
              <MDBox my={3} textAlign="start">
                <MDButton
                  variant="gradient"
                  color="success"
                  type="submit"
                  sx={{ p: "0 64px" }}
                  disabled={disabled}
                >
                  Save
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: "-48px" }}>
              <ProposalStageSummary
                displayColumns={[
                  DefinedColumns.Product,
                  DefinedColumns.Hours,
                  DefinedColumns.Days,
                  DefinedColumns.Total,
                ]}
                showSummaryTable={false}
              />
            </Grid>
          </Grid>
        </MDBox>
      </ProposalContext.Provider>
    </ProposalFormsContextProvider>
  );
}
