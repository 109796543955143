import { Company, CompanyStatus } from "generated/graphql";
import { mergeAll, omit } from "ramda";
import { zodUploadValidator } from "utils/validations/isValidUpload";
import * as z from "zod";

export const defaultValues = {
  name: "",
  status: CompanyStatus.PROSPECT,
  website: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  addressCountry: "US",
  contacts: {
    create: [
      {
        primary: true,
        SMSNotifications: true,
        title: "",
        firstName: "",
        lastName: "",
        role: "",
        phone: "",
        email: "",
      },
    ],
  },
  notificationTypes: {
    CONTACT: true,
    JOB: true,
    JOB_PHASE: true,
    OPPORTUNITY: true,
    PROPOSAL: true,
  }
};

export const defaultValuesSecondaryContact = {
  primary: false,
  title: "",
  firstName: "",
  lastName: "",
  role: "",
  phone: "",
  email: "",
  SMSNotifications: true,
};

export const getDefaultValues = (initialValues: Company) => {
  // We need to put the company contacts under upsert
  return mergeAll([
    defaultValues,
    omit(["contacts"])(initialValues),
    {
      contacts: { create: initialValues.contacts },
    },
  ]);
};

export const schema = z.object({
  name: z.string().min(1, "Name is Required"),
  status: z.string().min(1, "Status is Required"),
  website: z.string().url().optional().or(z.literal("")),
  email: z
    .string({ required_error: "Email is Required" })
    .regex(
      new RegExp(
        /(?:[a-z0-9+!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      ),
      "Email is invalid"
    ), // adding a regex here cos zod's validation allows emails like "test@example_.com" to pass which fails on the BE
  logo: z.string().nullable().optional(),
  addressLine1: z.string().nullable().optional(),
  addressLine2: z.string().nullable().optional(),
  addressCity: z.string().nullable().optional(),
  addressState: z.string().nullable().optional(),
  addressZip: z.string().nullable().optional(),
  addressCountry: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  contacts: z.object({
    create: z
      .array(
        z.object({
          title: z.string().optional(),
          firstName: z.string().min(1, "First Name is Required"),
          lastName: z.string().min(1, "Last Name is Required"),
          role: z.string().optional(),
          phone: z.string().optional(),
          email: z.string().email().optional(),
          primary: z.boolean(),
          SMSNotifications: z.boolean().optional().default(true),
        })
      )
      .refine(
        (val) => {
          if (!val || val?.length === 0) {
            return false;
          }

          if (!val[0].primary) {
            return false;
          }

          return true;
        },
        {
          message: "Primary Contact is Required",
        }
      ),
  }),
  notificationTypes: z.object({
    CONTACT: z.boolean().optional().default(true),
    JOB: z.boolean().optional().default(true),
    JOB_PHASE: z.boolean().optional().default(true),
    OPPORTUNITY: z.boolean().optional().default(true),
    PROPOSAL: z.boolean().optional().default(true),
  })
});
