import {
  requiresPlant as productRequiresPlant,
  requiresTrucking as productRequiresTrucking,
} from "DDD/action-objects/ProductCreate";
import { ProposalProduct } from "generated/graphql";
import { useGetProduct } from "./useGetProduct";

export default function useProposalProductDetails(proposalProduct?: ProposalProduct) {
  const { data: product } = useGetProduct({ id: proposalProduct?.material?.productId });

  return {
    requiresPlant: product ? productRequiresPlant.includes(product.type) : false,
    requiresTrucking: product ? productRequiresTrucking.includes(product.type) : false,
    useWeightToCalculateTransferTruckCost:
      proposalProduct?.plantProduct?.plant?.rateOnWeight ?? false,
  };
}
