import React, { useEffect, useRef, useContext, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { ProposalFormsContext } from "./providers/ProposalFormsContextProvider";

const DirtyFieldsTracker = React.memo(({ id }: { id: string }) => {
  const { setProductDirtyStatus } = useContext(ProposalFormsContext);
  const {
    getValues,
    getFieldState,
    formState: { isDirty, dirtyFields, defaultValues },
    watch,
  } = useFormContext();
  const lastKnownValues = useRef(null);
  const lastDirtyStatus = useRef(undefined);
  const checkDirtyStatus = useCallback((updated) => {
    if (lastKnownValues.current !== updated && typeof lastDirtyStatus.current !== "undefined") {
      lastKnownValues.current = updated;
      setProductDirtyStatus(id, lastDirtyStatus.current);
    }
  }, []);

  useEffect(() => {
    if (typeof lastDirtyStatus.current !== "undefined") {
      lastDirtyStatus.current = isDirty;
      setProductDirtyStatus(id, isDirty);
    } else {
      lastDirtyStatus.current = isDirty;
    }
  }, [isDirty]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      const valuesString = JSON.stringify(values);
      checkDirtyStatus(valuesString);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return null;
});

DirtyFieldsTracker.displayName = "DirtyFieldsTracker";

export default DirtyFieldsTracker;
