import AuthWall from "modules/AuthWall/AuthWall";
import AcceptInvitePage from "pages/signup/accept-invite";
import UserEditPage from "pages/users/edit";
import UsersInvitePage from "pages/users/invite";

const userRoutes = [
  {
    type: "internal",
    name: "Accept Invite",
    key: "accept-invite",
    route: "/accept-invite",
    component: <AcceptInvitePage />,
  },
  {
    type: "internal",
    name: "Edit User",
    key: "users.edit",
    route: "/users/:userId",
    component: (
      <AuthWall>
        <UserEditPage />
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Invite User",
    title: "Invite User",
    key: "users.invite",
    route: "/users/invite",
    component: (
      <AuthWall>
        <UsersInvitePage />
      </AuthWall>
    ),
  },
] as const;

export default userRoutes;
