import { Avatar } from "@mui/material";
import { useMemo } from "react";

export default function PreviewAvatar({
  value,
  isPreview = false,
}: {
  value: string;
  isPreview?: boolean;
}) {
  const previewImage = useMemo(() => {
    if (isPreview) {
      return value;
    }
    return `${import.meta.env.VITE_CDN_BASE_URL}/${value}`;
  }, [value, isPreview]);

  return <Avatar src={previewImage} sx={{ width: 56, height: 56 }} />;
}
