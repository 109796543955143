/**
 * Push if it doesn' exist
 * @param targetArr
 * @param inputArr
 * @param targetKey
 * @returns
 */
export function pushIfNotExists<T extends Object, U extends Object>(
  targetArr: T[],
  inputArr: U[],
  targetKey: keyof (T | U)
) {
  for (const s of inputArr) {
    if (!targetArr.some((x) => x[targetKey as string] === s[targetKey as string])) {
      targetArr.push({ ...(s as any as T) });
    }
  }
  return targetArr;
}
