import getLocalString from "constants/Localization";
import MDAlert from "components/MDAlert";
import { Employee, UpdateEmployeeInput } from "generated/graphql";
import useUpdateEmployee from "hooks/organization/employees/useUpdateEmployee";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function Update({ employee }: { employee: Employee }) {
  const [updateEmployee, { loading, error, isSuccess }] = useUpdateEmployee();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UpdateEmployeeInput>({
    defaultValues: { ...employee, rate: +employee.rate / 100 },
  });

  const onSubmit = async ({ id, firstName, lastName, rate }) => {
    await updateEmployee({
      variables: {
        input: {
          id,
          firstName,
          lastName,
          rate: rate * 100,
        },
      },
    });
    if (isSuccess) {
      navigate(getRoute("organization-settings.employees"));
    }
  };

  return (
    <>
      {error && (
        <MDAlert color="error">{getLocalString("organization.update-crew-failure")}</MDAlert>
      )}
      <Card>
        <MDBox pb={4} pl={4} component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={4}>
              <MDInput
                fullWidth
                margin="normal"
                error={errors.firstName}
                {...register("firstName", { required: true })}
                label="First name"
              />
              <MDInput
                fullWidth
                margin="normal"
                error={errors.lastName}
                {...register("lastName", { required: true })}
                label="Last name"
              />
              <MDInput
                type="number"
                fullWidth
                margin="normal"
                error={errors.rate}
                {...register("rate", { required: true, min: 1, valueAsNumber: true })}
                label="rate"
              />
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Update
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  );
}
