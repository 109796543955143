import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables,
  useUpdateOrganizationUserMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export default function useUpdateOrganizationUser() {
  const [mutation, { data: graphqlData, loading, error }] = useUpdateOrganizationUserMutation();
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateOrganizationUserMutation) => {
    return !!data?.updateOrganizationUser;
  }, []);

  const getData = useCallback(({ data }: { data?: UpdateOrganizationUserMutation }) => {
    return data?.updateOrganizationUser ?? null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData }) || null;
  }, [graphqlData]);

  const updateOrganizationUser = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          UpdateOrganizationUserMutation,
          UpdateOrganizationUserMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("users.update-success"),
        failureMessage: getLocalString("users.update-failed"),
      }
    ),
    []
  );

  return [updateOrganizationUser, { data, loading, error, isSuccess, getData }] as const;
}
