import { Company, GetCompaniesSortKey, SortDirection } from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import CompanyActionsCell from "modules/companies/CompanyActionsCell/CompanyActionsCell";
import { useMemo } from "react";
import { Cell } from "react-table";
import useGetCompanies from "./useGetCompanies";
import { Link } from "react-router-dom";
import { Icon, IconButton } from "@mui/material";
import { sortDirectionMap } from "constants/sortDirectionMap";

export const companiesSortKeyMap = {
  id: GetCompaniesSortKey.ID,
  name: GetCompaniesSortKey.NAME,
  addressLine1: GetCompaniesSortKey.ADDRESS,
  primaryContact: GetCompaniesSortKey.CONTACT,
};

export const companiesInitialSortDirectionKey = "asc";
export const companiesInitialSortDirectionValue = SortDirection.ASC;
export const companiesInitialSortKeyKey = "id";
export const companiesInitialSortKeyValue = GetCompaniesSortKey.NAME;

export default function useCompaniesTable() {
  const { filtering, sorting, offset } = usePaginatedVariables();

  const params = {
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sort: sortDirectionMap[sorting.sortDirection],
    sortKey: companiesSortKeyMap[sorting.sortKey] || companiesInitialSortKeyValue,
    sortDirection: sortDirectionMap[sorting.sortDirection] || companiesInitialSortDirectionValue,
  };

  const { data, loading, error, paginatorInfo, refetch } = useGetCompanies(params);

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }) => (
          <Link style={{ color: "inherit" }} to={`/companies/${value}`}>
            {value}
          </Link>
        ),
      },
      {
        Header: "Company Name",
        accessor: "name",
        Cell: ({ row, value }: Cell<Company>) => (
          <Link style={{ color: "inherit" }} to={`/companies/${row.original.id}`}>
            {value}
          </Link>
        ),
      },
      {
        Header: "Primary Contact",
        accessor: "primaryContact",
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          return (
            <>
              {value.phone && (
                <IconButton component="a" href={`tel:${value.phone}`}>
                  <Icon>local_phone</Icon>
                </IconButton>
              )}
              {value.email && (
                <IconButton component="a" href={`mailto:${value.email}`}>
                  <Icon>email</Icon>
                </IconButton>
              )}
            </>
          );
        },
      },
      { Header: "Company Address", accessor: "addressLine1" },
      {
        align: "center",
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: CompanyActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    sorting,
    paginatorInfo,
    refetch,
  } as const;
}
