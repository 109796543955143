import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import useGetPlants from "hooks/organization/plants/useGetPlants";
import { capitalCase } from "change-case";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import usePaginatedResources, { Pagination } from "hooks/usePaginatedResources";
import { mapPlantProductTypeOptions } from "constants/PlantProductTypes";

export default function PlantOptions({
  children,
}: {
  children: ({
    plantOptions,
    loading,
    search,
    plantProductOptions,
    plantsWithProductsOptions,
    ProductsOptionsByPlant,
    pagination,
  }: {
    plantOptions: SelectOptions;
    plantProductOptions: Record<SelectOptions[number]["value"], SelectOptions>;
    loading: boolean;
    plantsWithProductsOptions: SelectOptions;
    ProductsOptionsByPlant: (v: string) => SelectOptions;
    search: (v: string) => void;
    pagination: Pagination;
  }) => React.ReactNode;
}) {
  const { filtering, offset } = usePaginatedVariables();

  const { loading, data, error, paginatorInfo } = useGetPlants({
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    page: offset.page,
  });

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const plants = data ?? [];

  const plantOptions = plants.map((plant) => ({
    label: plant.name,
    value: plant.id,
  }));

  const plantProductOptions = plants.reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val.products.map((plantProduct) => ({
        label: capitalCase(plantProduct.type),
        value: plantProduct.id,
      })),
    };
  }, {});

  const plantsWithProductsOptions = plants.reduce((acc, val) => {
    return [
      ...acc,
      ...val.products.map((plantProduct) => ({
        label: `${val.name} - ${mapPlantProductTypeOptions(plantProduct.type)}`,
        value: plantProduct.id,
      })),
    ];
  }, []);

  const ProductsOptionsByPlant = (plantId: string) => {
    return plants.reduce((acc, val) => {
      if (val?.id == plantId) {
        return [
          ...acc,
          ...val.products.map((plantProduct) => ({
            label: `${mapPlantProductTypeOptions(plantProduct.type)}`,
            value: plantProduct.id,
          })),
        ];
      } else {
        return [...acc];
      }
    }, []);
  };

  return (
    <>
      {!loading && (
        <>
          {children({
            plantOptions,
            loading,
            search: filtering.setSearchTerm,
            plantProductOptions,
            plantsWithProductsOptions,
            ProductsOptionsByPlant,
            pagination,
          })}
        </>
      )}
    </>
  );
}
