import { User } from "generated/graphql";
import ViewCompanyMenuItem from "modules/companies/ViewCompanyMenuItem/ViewCompanyMenuItem";
import UpdateCompanyMenuItem from "modules/companies/UpdateCompanyMenuItem/UpdateCompanyMenuItem";
import ArchiveCompanyMenuItem from "modules/companies/ArchiveCompanyMenuItem/ArchiveCompanyMenuItem";
import MergeCompanyMenuItem from "modules/companies/MergeCompanyMenuItem/MergeCompanyMenuItem";

export default function CompanyMenuItems({
  id,
  closeMenu,
}: {
  id: User["id"];
  closeMenu: () => void;
}) {
  return (
    <>
      <ViewCompanyMenuItem closeMenu={closeMenu} id={id} />
      <UpdateCompanyMenuItem closeMenu={closeMenu} id={id} />
      <ArchiveCompanyMenuItem closeMenu={closeMenu} id={id} />
      <MergeCompanyMenuItem closeMenu={closeMenu} id={id} />
    </>
  );
}
