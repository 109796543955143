import AuthWall from "modules/AuthWall/AuthWall";
import Dashboard from "pages/index";
import TermsAndConditionsPage from "pages/terms-and-conditions";
import PublicMapPage from "pages/map/public-map";
import { Icon } from "@mui/material";
import MapPage from "pages/map/map";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";
import DownloadAppPage from "pages/download-app";

const generalRoutes = [
  {
    name: "Dashboard",
    key: "dashboard",
    route: "/",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <Dashboard />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Terms and Conditions",
    key: "terms-and-conditions",
    route: "/pages/terms-and-conditions",
    component: <TermsAndConditionsPage />,
  },
  {
    type: "link",
    icon: <Icon fontSize="medium">map</Icon>,
    name: "Maps",
    key: "map.view-map",
    route: "/map",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <MapPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Download App",
    title: "Download App",
    key: "download-app",
    route: "/download-app",
    component: <DownloadAppPage />,
  },
  {
    name: "Public Map",
    title: "Public Map",
    key: "public-map",
    route: "/public-map",
    component: <PublicMapPage />,
  },
] as const;

export default generalRoutes;
