import { Card, Grid } from "@mui/material";
import { sentenceCase } from "change-case";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { ReportType } from "generated/graphql";
import useGenerateReport from "hooks/organization/reports/useGenerateReport";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

const Generate = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();

  const [generateReport, { data }] = useGenerateReport();

  const downloadFileRef = useRef(null);

  useEffect(() => {
    if (data?.generateReport) {
      downloadFileRef.current.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${encodeURI(
        data.generateReport
      )}`;
      downloadFileRef.current.download = `bitumio-report.xlsx`;
      downloadFileRef.current.click();
    }
  }, [data]);

  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  let today = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
  today = `${today} 00:00:00`;

  const [date, setDate] = useState({ startDate: today, endDate: today });

  const changeDate = async (selectedDate: Array<Date>, type) => {
    const formattedDate = new Date(selectedDate[0].getTime() - tzoffset).toISOString().slice(0, 10);
    setDate((date) => ({ ...date, [type]: `${formattedDate} 00:00:00` }));
  };

  const dataValues = Object.values(ReportType).map((type) => ({
    label: sentenceCase(type),
    value: type,
  }));

  const onSubmit = (data) => generateReport({ variables: { ...data, ...date } });

  return (
    <Card>
      <MDBox my={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <MDBox mt={1} p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <CustomSelect
                label="Type"
                control={control}
                fullWidth
                selectVariant="standard"
                name="type"
                data={dataValues}
                {...register("type", { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body2">Start date</MDTypography>
              <MDDatePicker defaultValue={today} onChange={(val) => changeDate(val, "startDate")} />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body2">End date</MDTypography>
              <MDDatePicker defaultValue={today} onChange={(val) => changeDate(val, "endDate")} />
            </Grid>
            <Grid item xs={12}>
              <a ref={downloadFileRef} className="d-none" />
              <MDButton variant="gradient" color="success" type="submit">
                Save
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default Generate;
