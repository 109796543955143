import { ProposalProduct } from "generated/graphql";
import useGetOrganization from "hooks/organization/useGetOrganization";
import React, { useCallback, useEffect, useRef, useState } from "react";

export interface ProductFormStatus {
  isDirty: boolean;
  status: "fulfilled" | "idle" | "pending" | "rejected";
  attemptToSaveAt: number | null;
}

export interface ProposalFormsContextValue {
  productsFormsStatus: Record<ProposalProduct["id"], ProductFormStatus>;
  productsSubmitFunctions: Record<
    ProposalProduct["id"],
    () => Promise<{ status: boolean; data: any }>
  >;
  setProductSubmitFunction: (
    proposalProductId: ProposalProduct["id"],
    submitFn: () => void
  ) => void;
  setProductDirtyStatus: (productId: ProposalProduct["id"], isDirty: boolean) => void;
  setProductFormStatus: (
    productId: ProposalProduct["id"],
    status: "fulfilled" | "idle" | "pending" | "rejected"
  ) => void;
  autoSave?: boolean;
  organizationId?: string;
}

export const ProposalFormsContext = React.createContext<ProposalFormsContextValue>(
  {} as ProposalFormsContextValue
);

const ProposalFormsContextProvider = ({ children }: { children: JSX.Element }) => {
  const { data } = useGetOrganization();
  const stagesProductSubmitFunctionsRef = useRef({});
  const syncFormsTimers = useRef({});
  const autoSave = data?.proposalDefaults?.autoSave;
  const shouldAutoSave = useRef(false);
  const organizationId = data?.id;

  const [productsFormsStatus, setProductsFormsStatus] = useState<
    ProposalFormsContextValue["productsFormsStatus"]
  >({});

  const setProductSubmitFunction = useCallback(
    (productId: ProposalProduct["id"], submitFn: () => void) => {
      stagesProductSubmitFunctionsRef.current = {
        ...stagesProductSubmitFunctionsRef.current,
        [productId]: submitFn,
      };
    },
    []
  );

  const setProductFormStatus = useCallback(
    (productId, status) => {
      setProductsFormsStatus((prevState) => ({
        ...prevState,
        [productId]: {
          ...prevState[productId],
          status,
        },
      }));
    },
    [setProductsFormsStatus, data]
  );

  const setProductDirtyStatus = useCallback(
    (productId, isDirty) => {
      const everySeconds = 5000;
      const attemptToSaveAt = isDirty ? Date.now() + everySeconds : null;

      setProductsFormsStatus((prevState) => ({
        ...prevState,
        [productId]: {
          ...prevState[productId],
          isDirty,
          attemptToSaveAt,
        },
      }));

      if (!isDirty) {
        if (syncFormsTimers.current[productId]) {
          clearTimeout(syncFormsTimers.current[productId]);
        }
      } else {
        if (syncFormsTimers.current[productId]) {
          clearTimeout(syncFormsTimers.current[productId]);
        }

        if (shouldAutoSave.current) {
          syncFormsTimers.current[productId] = setTimeout(() => {
            stagesProductSubmitFunctionsRef.current[productId]?.();
          }, everySeconds);
        }
      }
    },
    [setProductsFormsStatus]
  );

  useEffect(() => {
    shouldAutoSave.current = autoSave;
  }, [autoSave]);

  useEffect(() => {
    return () => {
      for (const [_, timer] of Object.entries(syncFormsTimers.current)) {
        clearTimeout(timer as number);
      }
    };
  }, []);

  return (
    <ProposalFormsContext.Provider
      value={{
        setProductFormStatus,
        setProductSubmitFunction,
        productsFormsStatus,
        setProductDirtyStatus,
        productsSubmitFunctions: stagesProductSubmitFunctionsRef.current,
        autoSave,
        organizationId,
      }}
    >
      {children}
    </ProposalFormsContext.Provider>
  );
};

export default ProposalFormsContextProvider;
