import { Grid, Icon, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { SortDirection } from "generated/graphql";
import useGetEmployees from "hooks/organization/employees/useGetEmployees";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import ListEmployees from "modules/OrganizationEmployees/ListEmployees";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function EmployeesListPage() {
  // const { filtering, offset } = usePaginatedVariables();

  // const { loading, data, error } = useGetEmployees({
  //   first: offset.first,
  //   page: offset.page,
  //   sort: SortDirection.ASC,
  //   search: filtering.debouncedSearchTerm,
  // });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load job details. Please refresh this page or try again later.
        </MDAlert>
      )} */}
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Employees
              </MDTypography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <MDButton
                component={Link}
                type="button"
                to={getRoute("organization.create-employee")}
                color="blue"
              >
                <Icon>add</Icon>&nbsp; Create Employee
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ListEmployees />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
