import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions } from "@apollo/client";
import { getDataFromTree } from "@apollo/client/react/ssr";
import getLocalString from "constants/Localization";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import { useBitumioContext } from "providers/BitumioContext";
import { useCallback } from "react";
import * as Sentry from "@sentry/react";

type MutationAction = {
  hideMessage?: boolean;
};

export type MessageProps =
  | {
      hideMessage: true;
      successMessage?: never;
      failureMessage?: never;
      showValidationErrors?: never;
      hideSpecificError?: never;
    }
  | {
      hideMessage?: false;
      successMessage: string;
      failureMessage: string;
      showValidationErrors?: true;
      hideSpecificError?: string;
    };

export type MutationProps<T, R> = MutationFunctionOptions<R, T, DefaultContext, ApolloCache<any>>;

/**
 * @deprecated Please use useBitumioMutation
 */
export default function useMutationAction({ hideMessage }: MutationAction = {}) {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();

  const withMutationAction = useCallback(
    <T, R>(
        mutation: (
          props: MutationProps<T, R>
        ) => Promise<FetchResult<R, Record<string, any>, Record<string, any>>>,
        {
          isSuccess,
          successMessage,
          failureMessage,
          showValidationErrors,
          hideSpecificError,
        }: {
          isSuccess?: (result: any) => boolean;
          successMessage?: string;
          failureMessage?: string;
          showValidationErrors?: true;
          hideSpecificError?: string;
        } = {}
      ) =>
      async (
        props: MutationProps<T, R>
      ): Promise<
        FetchResult<R, Record<string, any>, Record<string, any>> & { success: boolean }
      > => {
        try {
          showLoader();
          const _result = await mutation(props);
          const result = {
            ..._result,
            success: isSuccess(_result.data),
          };

          if (hideMessage) {
            return result;
          }
          if (isSuccess(result.data)) {
            show({ message: successMessage });
          } else {
            show({ message: failureMessage, options: { variant: "error" } });

            Sentry.captureMessage(failureMessage);
          }
          return result;
        } catch (e) {
          if (!hideMessage) {
            const errorMessage =
              showValidationErrors && e.graphQLErrors?.[0].extensions?.category === "validation"
                ? e.message
                : failureMessage;
            if (errorMessage.indexOf(hideSpecificError) !== -1) {
              return e;
            }

            show({
              message: errorMessage || getLocalString("generic.failure"),
              options: { variant: "error" },
            });
          }
          return e;
        } finally {
          hideLoader();
        }
      },
    []
  );

  return withMutationAction;
}
