import { Opportunity } from "generated/graphql";
import UpdateOpportunityMenuItem from "modules/Opportunities/UpdateOpportunityMenuItem/UpdateOpportunityMenuItem";
import CancelOpportunityMenuItem from "modules/Opportunities/CancelOpportunityMenuItem/CancelOpportunityMenuItem";
import DeleteOpportunityMenuItem from "../DeleteOpportunityMenuItem/DeleteOpportunityMenuItem";

export default function OpportunityMenuItems({
  opportunity,
  closeMenu,
}: {
  opportunity: Opportunity;
  closeMenu: () => void;
}) {
  return (
    <>
      <UpdateOpportunityMenuItem closeMenu={closeMenu} object={opportunity} />
      <CancelOpportunityMenuItem closeMenu={closeMenu} object={opportunity} />
      <DeleteOpportunityMenuItem closeMenu={closeMenu} object={opportunity} />
    </>
  );
}
