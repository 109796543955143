import { Card } from "@mui/material";
import OpportunityForm from "components/opportunities/OpportunityForm/OpportunityForm";
import MDAlert from "components/MDAlert";
import { Opportunity, UpdateOpportunityInput } from "generated/graphql";
import useUpdateOpportunity from "hooks/opportunities/useUpdateOpportunity";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getRoute } from "utils/routing";

const UpdateOpportunity: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => {
  const [updateOpportunity, { loading, data: updatedOpportunity, isSuccess, getData, error }] =
    useUpdateOpportunity();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (input: UpdateOpportunityInput) => {
      const result = await updateOpportunity({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      return { success, data };
    },
    [updateOpportunity]
  );

  const handleSuccess = useCallback(
    (data) => {
      navigate(getRoute("opportunities.list"));
    },
    [navigate]
  );

  return (
    <>
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <Card>
        <OpportunityForm
          action="upsert"
          loading={loading}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          opportunity={(updatedOpportunity as Opportunity) ?? opportunity}
        />
      </Card>
    </>
  );
};

export default UpdateOpportunity;
