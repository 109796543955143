import { useGetTransferTrucksLazyQuery } from "generated/graphql";
import { useCallback } from "react";

export default function useGetTransferTrucksLazy() {
  const [getTransferTrucksLazyQuery, { data, loading, error }] = useGetTransferTrucksLazyQuery();

  const getTransferTrucks = useCallback(() => {
    getTransferTrucksLazyQuery();
  }, [getTransferTrucksLazyQuery]);

  const transferTrucks = data?.getTransferTrucks ?? [];
  const totalTransferTrucks = data?.getTransferTrucks?.length || null;

  return [
    getTransferTrucks,
    { data, loading, error, transferTrucks, totalTransferTrucks },
  ] as const;
}
