import { Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import getLocalString from "constants/Localization";
import { CreateCompanyInput } from "generated/graphql";
import useCreateCompany from "hooks/companies/useCreateCompany";
import { useIsCompanySearchable } from "hooks/companies/useIsCompanySearchable";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import CreateCompany from "modules/companies/CreateCompany/CreateCompany";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useBitumioContext } from "providers/BitumioContext";
import { useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getRoute } from "utils/routing";

export default function CreateCompanyPage() {
  const [createCompany, { loading, error, getData, isSuccess, data }] = useCreateCompany();
  const [companyIsSearchable, { data: isSearchable, loading: isSearchableLoading }] =
    useIsCompanySearchable();
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const interval = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();
  const fetchIsSearchable = async (id: string) => {
    showLoader();
    const result = await companyIsSearchable(id);
    if (result.data.isCompanySearchable) {
      hideLoader();
      clearInterval(interval.current);
      navigate(getRoute("companies.list"));
    }
  };
  const initiateInterval = (id: string) => {
    interval.current = setInterval(() => {
      fetchIsSearchable(id);
    }, 1000);
  };
  const onSubmit = useCallback(
    async (input: CreateCompanyInput) => {
      const result = await createCompany({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (success) {
        initiateInterval(result.data?.createCompany?.id);
        if (isSearchable) {
          navigate(getRoute("companies.list"));
        }
      }
      return { success, data };
    },
    [createCompany, isSearchable]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {error && (
        <MDAlert color="error">
          {getLocalString("companies.create-failure")} {parseGraphQLError(error)}
        </MDAlert>
      )}
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Create Company
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateCompany onSubmit={onSubmit} loading={loading || isSearchableLoading} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
