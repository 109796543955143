import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomSelect, { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { OrganizationProductType, ProductType } from "generated/graphql";
import CrewOptions from "modules/crews/CrewOptions";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  jobMeasurementPlurals,
  jobMeasurementPounds,
} from "utils/product-types/getJobMeasurementMethodOptions";
import PlantOptions from "modules/plants/PlantOptions";
import AreaCoverage from "./AreaCoverage";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import CreateAdditionalCostModal from "modules/additionalCosts/CreateAdditionalCostModal";
import MDButton from "components/MDButton";
import AdditionalCostOptions from "modules/additionalCosts/AdditionalCostOptions";
import EquipmentPieceOptions from "modules/equipmentPieces/EquipmentPieceOptions";
import CreatePlantModal from "modules/plants/CreatePlantModal";
import { isNilOrEmpty } from "ramda-adjunct";
import CreateEquipmentPieceModal from "modules/equipmentPieces/CreateEquipmentPieceModal";
import { concreteProducts, requiresPlant } from "DDD/action-objects/ProductCreate";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";

export default function ProductFormFields({
  action,
  materialCostTypeOptions,
  jobMeasurementMethodOptions,
  labourCostTypeOptions,
  productType,
  isSubcontracted,
  setIsSubcontracted,
  isUnitPrice,
  setIsUnitPrice,
  canBeMeasuredInTons,
}: {
  action: "create" | "update";
  materialCostTypeOptions: SelectOptions;
  jobMeasurementMethodOptions: SelectOptions;
  labourCostTypeOptions: SelectOptions;
  productType: OrganizationProductType;
  isUnitPrice: boolean;
  setIsUnitPrice: React.Dispatch<React.SetStateAction<boolean>>;
  isSubcontracted: boolean;
  setIsSubcontracted: React.Dispatch<React.SetStateAction<boolean>>;
  canBeMeasuredInTons: boolean;
}) {
  const {
    register,
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();

  // const [isSubcontracted, setIsSubcontracted] = useState(false);

  const inputKey = action === "create" ? "connect" : "sync";

  const equipmentPieces = useFieldArray({
    control,
    name: `equipmentPieces.${inputKey}` as const,
    keyName: "key",
  });

  const additionalCosts = useFieldArray({
    control,
    name: `additionalCosts.${inputKey}` as const,
    keyName: "key",
  });

  const jobMeasurementMethod = watch("measurementJob");
  const productionRateType = watch("productionRateType");
  const plantId = watch("plantId");

  return (
    <>
      <MDTypography variant="h5">Product Information</MDTypography>
      <MDBox mt={1}>
        <input type="hidden" {...register("organizationProductTypeId")} />
        <input type="hidden" {...register("productType")} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={12}>
            <MDBox display="flex" gap={2}>
              <Controller
                name="subcontracted"
                control={control}
                render={({ field }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          disabled={isUnitPrice}
                          onChange={(event) => {
                            field.onChange(event.target.checked);
                            setIsSubcontracted(!isSubcontracted);
                          }}
                        />
                      }
                      label="Subcontracted product"
                    />
                  </FormGroup>
                )}
              />
              {productType.type !== ProductType.GENERIC && (
                <Controller
                  name="unitPriceProduct"
                  control={control}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.value}
                            disabled={isSubcontracted}
                            onChange={(event) => {
                              field.onChange(event.target.checked);
                              setIsUnitPrice(!isUnitPrice);
                            }}
                          />
                        }
                        label="Unit Priced product"
                      />
                    </FormGroup>
                  )}
                />
              )}
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormField
              type="text"
              label="Name"
              placeholder="Name"
              error={errors.name}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              type="text"
              label="Proposal Product Name"
              placeholder="External Product Name"
              error={errors.externalProductName}
              {...register("externalProductName")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomSelect
              label="Measurement Method"
              control={control}
              fullWidth
              selectVariant="standard"
              data={jobMeasurementMethodOptions}
              name="measurementJob"
              {...register("measurementJob")}
            />
            <FormHelperText error sx={{ marginLeft: "0px" }}>
              {errors?.measurementJob?.message}
            </FormHelperText>
          </Grid>
          {isUnitPrice && (
            <>
              <Grid item xs={12} md={6}>
                <FormField
                  type="number"
                  inputProps={{ step: "1" }}
                  disabled={!jobMeasurementMethod}
                  label={`How many ${
                    jobMeasurementMethod ? jobMeasurementPlurals[jobMeasurementMethod] : "N/A"
                  } can your crew complete in a day?`}
                  placeholder="1"
                  error={errors.dailyUnitsComplete}
                  {...register("dailyUnitsComplete", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrencyFormField
                  type="number"
                  inputProps={{ step: "0.01", min: "0" }}
                  label="Unit Price"
                  {...currencyEndAdornment}
                  placeholder="Dollars"
                  name="unitPrice"
                  error={errors.unitPrice}
                  control={control}
                />
                <FormHelperText error sx={{ marginLeft: "0px" }}>
                  {errors?.measurementJob?.message}
                </FormHelperText>
              </Grid>
            </>
          )}
          {!isSubcontracted && !isUnitPrice && (
            <>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label="Material Cost Type"
                  control={control}
                  fullWidth
                  selectVariant="standard"
                  data={materialCostTypeOptions}
                  name="measurementMaterial"
                  {...register("measurementMaterial")}
                />
                <FormHelperText error sx={{ marginLeft: "0px" }}>
                  {errors?.measurementMaterial?.message}
                </FormHelperText>
              </Grid>
              {!requiresPlant.includes(productType.type as ProductType) && (
                <Grid item xs={12} md={6}>
                  <CurrencyFormField
                    type="number"
                    inputProps={{ step: "0.01", min: "0" }}
                    label="Material Cost"
                    {...currencyEndAdornment}
                    placeholder="Dollars"
                    name="costMaterial"
                    error={errors.costMaterial}
                    control={control}
                  />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <CustomSelect
                  label="Labour Cost Type"
                  control={control}
                  fullWidth
                  selectVariant="standard"
                  data={labourCostTypeOptions}
                  name="labourCostType"
                  {...register("labourCostType")}
                />
                <FormHelperText error sx={{ marginLeft: "0px" }}>
                  {errors?.labourCostType?.message}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={6}>
                {canBeMeasuredInTons ? (
                  <FormField
                    type="number"
                    inputProps={{ step: "1" }}
                    disabled={!jobMeasurementMethod}
                    label={`${
                      !!productionRateType
                        ? "Crew production rate in tons per hour"
                        : `How many ${
                            jobMeasurementMethod
                              ? jobMeasurementPlurals[jobMeasurementMethod]
                              : "N/A"
                          } can your crew complete in a day?`
                    } `}
                    placeholder="1"
                    error={errors.dailyUnitsComplete}
                    {...register("dailyUnitsComplete", { valueAsNumber: true })}
                  />
                ) : (
                  <FormField
                    type="number"
                    inputProps={{ step: "1" }}
                    disabled={!jobMeasurementMethod}
                    label={`How many ${
                      jobMeasurementMethod ? jobMeasurementPlurals[jobMeasurementMethod] : "N/A"
                    } can your crew complete in a day?`}
                    placeholder="1"
                    error={errors.dailyUnitsComplete}
                    {...register("dailyUnitsComplete", { valueAsNumber: true })}
                  />
                )}
              </Grid>
              {(productType.type === ProductType.SLURRY_SEAL ||
                productType.type === ProductType.MICROSURFACING) && (
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    label={`Application Rate ${
                      jobMeasurementMethod
                        ? "(" + jobMeasurementPounds[jobMeasurementMethod] + ")"
                        : ""
                    }`}
                    placeholder="1"
                    error={errors.applicationRate}
                    {...register("attributes.applicationRate", { valueAsNumber: true })}
                  />
                </Grid>
              )}
              <AreaCoverage productType={productType} action={action} />
              {canBeMeasuredInTons && (
                <Grid item xs={12} md={6}>
                  <Controller
                    name="productionRateType"
                    control={control}
                    render={({ field }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!!field.value}
                              onChange={(event) => {
                                field.onChange(event.target.checked);
                              }}
                            />
                          }
                          label="Use Tons Per Hour For Production Rate"
                        />
                      </FormGroup>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <CrewOptions>
                  {({ crewOptions }: { crewOptions: SelectOptions }) => (
                    <CustomSelect
                      label="What crew is needed for this product?"
                      control={control}
                      fullWidth
                      selectVariant="standard"
                      data={crewOptions}
                      name="crewId"
                      {...register("crewId")}
                    />
                  )}
                </CrewOptions>
                <FormHelperText error sx={{ marginLeft: "0px" }}>
                  {errors?.crewId?.message}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrencyFormField
                  type="number"
                  label="Minimum Proposal Cost"
                  inputProps={{ max: "9999999" }}
                  placeholder="Dollars"
                  name="costMinimumProposal"
                  control={control}
                  {...currencyEndAdornment}
                  error={errors.costMinimumProposal}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrencyFormField
                  type="number"
                  label="Minimum Product Cost"
                  inputProps={{ max: "9999999" }}
                  placeholder="Dollars"
                  name="costMinimumProduct"
                  error={errors.costMinimumProduct}
                  {...currencyEndAdornment}
                  control={control}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="markupPercentage"
              render={({ field, fieldState: { error } }) => (
                <FormField
                  type="number"
                  inputProps={{ min: "0", max: "100" }}
                  label="Markup Percentage"
                  placeholder="Percentage"
                  error={error}
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                  value={field.value >= 0 ? `${field.value}` : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              type="number"
              inputProps={{ min: "0", max: "100" }}
              label="Indirect Percentage"
              placeholder="Percentage"
              name="indirectPercentage"
              error={errors.indirectPercentage}
              {...register("indirectPercentage", { valueAsNumber: true })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              type="number"
              inputProps={{ min: "0", max: "100" }}
              label="Overhead Percentage"
              placeholder="Percentage"
              name="overheadPercentage"
              error={errors.overheadPercentage}
              {...register("overheadPercentage", { valueAsNumber: true })}
            />
          </Grid>
          {!isSubcontracted &&
            !isUnitPrice &&
            requiresPlant.includes(productType.type as ProductType) && (
              <PlantOptions>
                {({ plantOptions, plantProductOptions, loading, search }) =>
                  loading ? (
                    <div>Loading ...</div>
                  ) : (
                    <>
                      <Controller
                        name="plantId"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Grid item xs={12} md={6}>
                              <AutoComplete
                                options={plantOptions}
                                field={field}
                                loading={loading}
                                error={(error?.message ?? "") as string}
                                label="Plant"
                                search={search}
                              />
                            </Grid>
                            {plantOptions.length === 0 && (
                              <Grid item xs={12} md={6}>
                                <CreatePlantModal />
                              </Grid>
                            )}
                          </>
                        )}
                      />
                      <Controller
                        name="plantProductId"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Grid item xs={12} md={6}>
                              <AutoComplete
                                disabled={isNilOrEmpty(plantId)}
                                options={plantProductOptions[plantId] ?? []}
                                field={field}
                                loading={loading}
                                error={(error?.message ?? "") as string}
                                label="Plant Product"
                                search={search}
                              />
                            </Grid>
                          </>
                        )}
                      />
                    </>
                  )
                }
              </PlantOptions>
            )}
          <Grid item xs={12}>
            <Controller
              control={control}
              name="statementOfWork"
              render={({ field, fieldState: { error } }) => (
                <>
                  <WYSIWYG
                    type="proposalProduct"
                    content={field.value}
                    onChange={(content) => field.onChange(content)}
                    title="Statement Of Work"
                  />
                  {error && error?.message && (
                    <FormHelperText
                      sx={{
                        color: "red",
                      }}
                    >
                      {error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          {!isSubcontracted && !isUnitPrice && (
            <>
              <Grid item xs={12}>
                <EquipmentPieceOptions>
                  {({ equipmentPieceOptions, loading, search, pagination }) => (
                    <>
                      <MDBox display="flex" alignItems="center" gap={4}>
                        <MDBox minWidth="160px">
                          <MDTypography variant="h6">Equipment Pieces</MDTypography>
                        </MDBox>
                        <MDButton
                          sx={{ mb: 0 }}
                          type="button"
                          color="blue"
                          onClick={() => {
                            equipmentPieces.append({ id: null, quantity: 1, name: "" });
                          }}
                        >
                          Add Equipment Piece
                        </MDButton>
                        <CreateEquipmentPieceModal />
                      </MDBox>
                      {equipmentPieceOptions.length > 0 || equipmentPieces.fields.length > 0
                        ? equipmentPieces.fields.map((field, index) => (
                            <Grid
                              container
                              key={`equipmentPieces.${inputKey}.${index}`}
                              spacing={3}
                              mb={2}
                            >
                              <Grid item xs={12} md={6}>
                                <Controller
                                  name={`equipmentPieces.${inputKey}.${index}.id` as const}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <ScrollAsyncAutocomplete
                                      {...field}
                                      value={{
                                        label:
                                          equipmentPieceOptions.find((x) => x.value === field.value)
                                            ?.label ??
                                          getValues(`equipmentPieces.${inputKey}.${index}.name`) ??
                                          "",
                                        value: field.value,
                                      }}
                                      options={equipmentPieceOptions}
                                      loading={loading}
                                      label="Equipment Piece"
                                      search={search}
                                      onLoadMore={pagination.loadMore}
                                      hasMore={pagination.hasNextPage}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={10} md={5}>
                                <FormField
                                  type="number"
                                  placeholder="Equipment Piece Quantity"
                                  label="Equipment Piece Quantity"
                                  error={errors["equipmentPieces"]?.[inputKey]?.[index]?.quantity}
                                  {...register(`equipmentPieces.${inputKey}.${index}.quantity`, {
                                    valueAsNumber: true,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={2} md={1}>
                                <IconButton
                                  //@ts-ignore
                                  color="negative"
                                  onClick={() => {
                                    equipmentPieces.remove(index);
                                  }}
                                >
                                  <Icon fontSize="large" color="inherit">
                                    delete
                                  </Icon>
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))
                        : null}
                    </>
                  )}
                </EquipmentPieceOptions>
              </Grid>
              <Grid item xs={12}>
                <AdditionalCostOptions>
                  {({ additionalCostOptions, loading, search, pagination }) => (
                    <>
                      <MDBox display="flex" alignItems="center" gap={4}>
                        <MDBox minWidth="160px">
                          <MDTypography variant="h6">Additional Costs</MDTypography>
                        </MDBox>
                        <MDButton
                          type="button"
                          color="blue"
                          marginBottom={0}
                          onClick={() => {
                            additionalCosts.append({ id: null, type: null, name: "" });
                          }}
                        >
                          Add Additional Costs
                        </MDButton>
                        <CreateAdditionalCostModal />
                      </MDBox>
                      {additionalCostOptions.length > 0 || additionalCosts.fields.length > 0
                        ? additionalCosts.fields.map((field, index) => (
                            <Grid container key={index} spacing={3} mb={2}>
                              <Grid item xs={12} md={6}>
                                <Controller
                                  name={`additionalCosts.${inputKey}.${index}.id`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <ScrollAsyncAutocomplete
                                      {...field}
                                      value={{
                                        label:
                                          additionalCostOptions.find((x) => x.value === field.value)
                                            ?.label ??
                                          getValues(`additionalCosts.${inputKey}.${index}.name`) ??
                                          "",
                                        value: field.value,
                                      }}
                                      options={additionalCostOptions}
                                      loading={loading}
                                      label="Additional cost"
                                      search={search}
                                      onLoadMore={pagination.loadMore}
                                      hasMore={pagination.hasNextPage}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={2} md={1}>
                                <IconButton
                                  onClick={() => {
                                    additionalCosts.remove(index);
                                  }}
                                >
                                  <Icon fontSize="large" color="error">
                                    delete
                                  </Icon>
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))
                        : null}
                    </>
                  )}
                </AdditionalCostOptions>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </>
  );
}
