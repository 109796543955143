import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  SubmitHandler,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { getDefaultValues, schema } from "DDD/action-objects/UserEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateOrganizationUserInput, User, UserRole } from "generated/graphql";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField/FormField";
import { Controller } from "react-hook-form";
import MDButton from "components/MDButton";
import UserAvatarFormField from "../UserAvatarFormField/UserAvatarFormField";
import CountrySelect from "components/CountrySelect/CountrySelect";
import StateSelect from "components/StateSelect/StateSelect";
import { useEffect } from "react";
import PhoneInput from "components/PhoneInput/PhoneInput";

export interface UserFormProps {
  onSubmit: SubmitHandler<UpdateOrganizationUserInput>;
  loading: boolean;
  user: User;
}

export default function UserForm({ onSubmit, loading, user }: UserFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm<UpdateOrganizationUserInput>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(user),
  });

  return (
    <>
      <MDBox
        component="form"
        role="form"
        onSubmit={handleSubmit(async ({ avatar, ...rest }: UpdateOrganizationUserInput) => {
          // @ts-expect-error: FIX update types
          const { success, data: updatedUser } = await onSubmit({
            ...rest,
            avatar: avatar?.[0] ? avatar[0] : null,
          });
          if (success) {
            reset(getDefaultValues(updatedUser));
          }
        })}
      >
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6} mb={2}>
              <MDTypography variant="h4" fontWeight="medium">
                Updating {user.firstName} {user.lastName}
              </MDTypography>
            </Grid>
            <Grid item xs={12} lg={6} mb={2}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8} mb={2}>
            <MDBox mt={3}>
              <FormFields
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                roles={user.roles}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={4} mb={2}>
            <MDBox mt={3}>
              <UserAvatarFormField
                register={register}
                watch={watch}
                currentAvatarUrl={user.avatar}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

function FormFields({
  register,
  control,
  setValue,
  errors,
  roles,
}: {
  register: UseFormRegister<UpdateOrganizationUserInput>;
  control: Control<UpdateOrganizationUserInput, any>;
  errors: FieldErrorsImpl<DeepRequired<UpdateOrganizationUserInput>>;
  setValue: UseFormSetValue<UpdateOrganizationUserInput>;
  roles: User["roles"];
}) {
  return (
    <Card>
      <MDBox p={3}>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="First Name"
                placeholder="First Name"
                error={errors.firstName}
                {...register("firstName")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Last Name"
                placeholder="Last Name"
                error={errors.lastName}
                {...register("lastName")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Email"
                placeholder="Email"
                required
                error={errors.email}
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => <PhoneInput {...field} label="Phone" error={errors.phone} />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <MDBox mb={2}>
                {!roles.includes(UserRole.EMPLOYEE) && !roles.includes(UserRole.SUBCONTRACTOR) && (
                  <Controller
                    control={control}
                    name="role"
                    render={({ field, field: { value } }) => (
                      <FormControl
                        fullWidth
                        sx={
                          value
                            ? {
                                "&": {
                                  mt: "3px",
                                },
                              }
                            : {
                                "&": {
                                  py: "10px",
                                },
                              }
                        }
                      >
                        <InputLabel
                          id="role-select-label"
                          sx={{
                            "&": {
                              marginLeft: "-12px",
                            },
                          }}
                        >
                          Role
                        </InputLabel>
                        <Select
                          labelId="role-select-label"
                          label="Role"
                          placeholder="Please select One"
                          variant="standard"
                          fullWidth
                          sx={{
                            "& > #mui-component-select-role": {
                              padding: "0 0 5px !important",
                            },
                          }}
                          {...field}
                        >
                          <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
                          <MenuItem value={UserRole.FOREMAN}>Foreman</MenuItem>
                          <MenuItem value={UserRole.SALES_PERSON}>Sales Person</MenuItem>
                          <MenuItem value={UserRole.OFFICE_MANAGER}>Office Manager</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Address Line 1"
                {...register("addressLine1")}
                error={errors.addressLine1}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Address Line 2"
                {...register("addressLine2")}
                error={errors.addressLine2}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Address City"
                {...register("addressCity")}
                error={errors.addressCity}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <StateSelect
                name="addressState"
                countryFieldName="addressCountry"
                control={control}
                setValue={setValue}
                label="Address State"
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Address Zip"
                {...register("addressZip")}
                error={errors.addressZip}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <CountrySelect name="addressCountry" label="Address Country" control={control} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
