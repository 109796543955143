import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Checkbox,
  InputLabel,
  TextareaAutosize,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDSelect from "components/MDSelect/MDSelect";
import MDTypography from "components/MDTypography";
import {
  CreateNotificationTriggerInput,
  NotificationTriggerAdditionalRecipientTypes,
  NotificationTriggerFragmentFragment,
  NotificationTriggerType,
  UpdateNotificationTriggerInput,
} from "generated/graphql";
import React, { useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import FormField from "components/FormField/FormField";
import * as z from "zod";

import useGetOrganizationUsers from "hooks/users/useGetOrganizationUsers";
import CustomMultipleSelect from "components/Shared/CustomMultipleSelect/CustomMultipleSelect";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import MDButton from "components/MDButton";
import { capitalCase } from "change-case";
import { zodResolver } from "@hookform/resolvers/zod";
import { notificationTriggerEventsToTypes } from "../../constants/notificationTriggerEventsToTypes";
import { PlaceHolderTypes } from "../../constants/PlaceholderTypes";
import PlaceHolderSelect from "components/PlaceHolderSelect/PlaceHolderSelect";

type NotificationTriggerFormInput = CreateNotificationTriggerInput | UpdateNotificationTriggerInput;
interface NotificationTriggerFormProps {
  onSubmit: (values: NotificationTriggerFormInput) => void;
  loading?: boolean;
  notificationTrigger?: NotificationTriggerFragmentFragment;
}
const optionalObject = z
  .object({
    sendToContact: z.boolean(),
    sendToUser: z.boolean(),
    sendToAllRecipients: z.boolean(),
    additionalRecipientTypes: z.array(z.string().or(z.null())),
  })
  .partial();

const schema = z
  .object({
    type: z.string().min(1, "Type is required"),
    event: z.string().min(1, "event is required"),
    active: z.boolean(),
    smsContent: z.string(),
    isEmail: z.boolean(),
    isSMS: z.boolean(),
    content: z.string().min(1, "Content is required"),
    name: z.string().min(1, "Name is required"),
    delay: z.number().min(0, "Delay is required"),
    emailSubject: z.string(),
    additionalRecipients: z.object({
      sync: z.array(z.string()),
    }),
  })
  .merge(optionalObject)
  .superRefine(
    (
      {
        sendToContact,
        sendToUser,
        sendToAllRecipients,
        additionalRecipientTypes,
        additionalRecipients,
      },
      ctx
    ) => {
      if (
        !sendToContact &&
        !sendToUser &&
        !sendToAllRecipients &&
        additionalRecipientTypes.length < 1 &&
        additionalRecipients.sync.length < 1
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["sendToContact"],
          message: "Must choose at least one recipient",
        });
      }
    }
  );

export default function NotificationTriggerForm({
  onSubmit,
  loading,
  notificationTrigger,
}: NotificationTriggerFormProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<NotificationTriggerFormInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      id: notificationTrigger?.id,
      type: notificationTrigger?.type,
      event: notificationTrigger?.event,
      content: notificationTrigger?.content ?? "",
      isEmail: notificationTrigger?.isEmail ?? false,
      isSMS: notificationTrigger?.isSMS ?? false,
      smsContent: notificationTrigger?.smsContent ?? "",
      sendToContact: notificationTrigger?.sendToContact ?? false,
      name: notificationTrigger?.name,
      delay: notificationTrigger?.delay ?? 0,
      additionalRecipients: notificationTrigger
        ? { sync: notificationTrigger?.additionalRecipients?.map((r) => r.id) }
        : { sync: [] },
      additionalRecipientTypes: notificationTrigger?.additionalRecipientTypes ?? [],
      sendToAllRecipients: notificationTrigger?.sendToAllRecipients ?? false,
      active: notificationTrigger?.active ?? false,
      sendToUser: notificationTrigger?.sendToUser ?? false,
      emailSubject: notificationTrigger?.emailSubject ?? "",
    },
  });
  const { data: organizationUsers, loading: loadingUser } = useGetOrganizationUsers({
    first: 100,
  });

  const [currentType, setCurrentType] = useState<NotificationTriggerType>(
    notificationTrigger?.type
  );
  const setTextAreaValue = (e: any) => {
    const tArea = document.querySelector("#email-text-area") as any;
    const pos = tArea.selectionStart;
    const textAreaValue = getValues("smsContent");
    setValue(
      "smsContent",
      `${textAreaValue.slice(0, pos)}${e.target.value}${textAreaValue.slice(pos)}`
    );
  };

  const placeholderTypeKey = useMemo(() => {
    const types: {
      [k: string]: PlaceHolderTypes;
    } = {
      [NotificationTriggerType.OPPORTUNITY]: "opportunity",
      [NotificationTriggerType.PROPOSAL]: "proposal",
      [NotificationTriggerType.JOB]: "job",
      [NotificationTriggerType.JOB_PHASE]: "jobPhase",
      [NotificationTriggerType.CONTACT]: "contact",
    };

    return types[currentType] ?? null;
  }, [currentType]);

  const getRecipientsLabel = (id: string) => {
    const organizationUser = organizationUsers.find((x) => x.id === id);
    return `${organizationUser?.firstName} ${organizationUser?.lastName}`;
  };
  return (
    <MDBox
      role="form"
      component="form"
      onSubmit={handleSubmit(async (data) => {
        await onSubmit(data);
      })}
      px={4}
    >
      <MDBox mt={2} py={2}>
        <Grid spacing={3} container py={3}>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <MDSelect
                  {...field}
                  readOnly={!!notificationTrigger}
                  onChange={(e) => {
                    field.onChange(e.target.value as NotificationTriggerType);
                    setCurrentType(e.target.value as NotificationTriggerType);
                  }}
                  error={!!errors?.type?.message}
                  name="type"
                  value={field.value ?? currentType}
                  fullWidth
                  label="Select Type"
                  options={Object.keys(NotificationTriggerType).map((k) => ({
                    label: capitalCase(k),
                    value: k,
                  }))}
                />
              )}
            />
          </Grid>
          {currentType && (
            <Grid item xs={12}>
              <Controller
                name="event"
                control={control}
                render={({ field }) => (
                  <MDSelect
                    {...field}
                    readOnly={!!notificationTrigger}
                    fullWidth
                    error={!!errors?.event?.message}
                    label="Select Event"
                    options={notificationTriggerEventsToTypes[currentType].map((k) => ({
                      label: capitalCase(k),
                      value: k,
                    }))}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormField label="Name" type="text" {...register("name")} />
            {errors?.name?.message && (
              <MDTypography variant="caption" color="error">
                {errors?.name?.message}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <>
                  <MDTypography variant="caption">Trigger Status</MDTypography>
                  <Switch {...field} checked={field.value} />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Select Days Delay"
              inputProps={{ step: 1 }}
              type="number"
              {...register("delay", { valueAsNumber: true })}
            />
            {errors?.delay?.message && (
              <MDTypography variant="caption" color="error">
                {errors?.delay?.message}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Recipients</InputLabel>
            {currentType !== NotificationTriggerType.CONTACT && (
              <>
                <FormGroup>
                  <Controller
                    name="sendToContact"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label="Main Contact"
                        control={<Checkbox {...field} checked={field.value} />}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="sendToUser"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label={
                          currentType === NotificationTriggerType.OPPORTUNITY
                            ? "Sales Person"
                            : currentType === NotificationTriggerType.JOB
                            ? "Project Manager"
                            : "Estimator"
                        }
                        control={<Checkbox {...field} checked={field.value} />}
                      />
                    )}
                  />
                </FormGroup>
                {currentType === NotificationTriggerType.PROPOSAL && (
                  <FormGroup>
                    <Controller
                      name="sendToAllRecipients"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          label="All Proposal Recipients"
                          control={<Checkbox {...field} checked={field.value} />}
                        />
                      )}
                    />
                  </FormGroup>
                )}
                {currentType === NotificationTriggerType.JOB_PHASE &&
                  [
                    {
                      label: "Foreman",
                      value: NotificationTriggerAdditionalRecipientTypes.FOREMAN,
                    },
                  ].map((type) => (
                    <FormControlLabel
                      key={type.value}
                      control={
                        <Checkbox
                          defaultChecked={
                            getValues("additionalRecipientTypes").indexOf(type.value) > -1
                          }
                          name={"checkbox"}
                          size="small"
                          value={type.value}
                          {...register("additionalRecipientTypes")}
                        />
                      }
                      label={type.label}
                    ></FormControlLabel>
                  ))}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {organizationUsers?.length > 0 && !loadingUser && (
              <Controller
                name="additionalRecipients"
                control={control}
                render={({ field }) => (
                  <CustomMultipleSelect
                    {...field}
                    fullWidth
                    onChange={(e) => {
                      field.onChange({
                        sync: e?.map((x) => x.value as string),
                      });
                    }}
                    label="Additional Recipients"
                    value={
                      field.value?.sync?.map((s) => ({
                        label: getRecipientsLabel(s as string),
                        value: s,
                      })) ?? []
                    }
                    options={organizationUsers?.map((user) => ({
                      value: user.id,
                      label: `${user.firstName} ${user.lastName}`,
                    }))}
                  />
                )}
              />
            )}
          </Grid>

          {errors?.sendToContact?.message && (
            <Grid item xs={12}>
              <MDTypography variant="caption" color="error">
                {errors?.sendToContact?.message}
              </MDTypography>
            </Grid>
          )}
          <Grid item xs={12} py="15px">
            <MDTypography variant="h5">Email Settings</MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="isEmail"
              render={({ field }) => (
                <>
                  <Switch {...field} checked={field.value} />
                  <MDTypography variant="caption">Enable Email Trigger</MDTypography>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="emailSubject"
              render={({ field }) => (
                <>
                  <WYSIWYG
                    onChange={(e) => field.onChange(e)}
                    title="Email Subject"
                    type={placeholderTypeKey}
                    content={field.value}
                  />
                  {errors?.content?.message && (
                    <MDTypography variant="caption" color="error">
                      {errors?.emailSubject?.message}
                    </MDTypography>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="content"
              render={({ field }) => (
                <>
                  <WYSIWYG
                    onChange={(e) => field.onChange(e)}
                    title="Content"
                    type={placeholderTypeKey}
                    content={field.value}
                  />
                  {errors?.content?.message && (
                    <MDTypography variant="caption" color="error">
                      {errors?.content?.message}
                    </MDTypography>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <MDTypography variant="h5">SMS Settings</MDTypography>
              {placeholderTypeKey && (
                <MDBox sx={{ marginLeft: "auto" }}>
                  <PlaceHolderSelect
                    type={placeholderTypeKey}
                    value=""
                    onChange={setTextAreaValue}
                  />
                </MDBox>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="isSMS"
              render={({ field }) => (
                <>
                  <Switch {...field} checked={field.value} />
                  <MDTypography variant="caption">Enable SMS Trigger</MDTypography>
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="smsContent"
            render={({ field }) => (
              <>
                <TextareaAutosize
                  {...field}
                  minRows={20}
                  style={{ minWidth: "100%" }}
                  id="email-text-area"
                />
                {errors?.content?.message && (
                  <MDTypography variant="caption" color="error">
                    {errors?.smsContent?.message}
                  </MDTypography>
                )}
              </>
            )}
          />

          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="flex-end" mt={2}>
              <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                Save
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
