import { NotificationTriggerFragmentFragment } from "generated/graphql";
import UpdateEmailTriggerMenuItem from "../UpdateEmailTriggerMenuItem/UpdateEmailTriggerMenuItem";

export default function EmailTriggerMenuItems({
  id,
  closeMenu,
}: {
  id: NotificationTriggerFragmentFragment["id"];
  closeMenu: () => void;
}) {
  return (
    <>
      <UpdateEmailTriggerMenuItem closeMenu={closeMenu} id={id} />
    </>
  );
}
