import ActionMenuItem from "components/Shared/ActionMenuItem/ActionMenuItem";
import { Employee } from "generated/graphql";
import Delete from "./Delete";

export default function MenuItems({
  id,
  closeMenu,
}: {
  id: Employee["id"];
  closeMenu: () => void;
}) {
  const closeDelete = () => {
    closeMenu();
    location.reload();
  };
  return (
    <>
      <ActionMenuItem
        closeMenu={closeMenu}
        route={`/organization/employees/${id}/update`}
        text="Edit"
      />
      <Delete closeMenu={closeDelete} id={id} />
    </>
  );
}
