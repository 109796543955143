import { zodResolver } from "@hookform/resolvers/zod";
import { Print } from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import {
  UpdateWorkOrderInputSchema,
  cleanUpdateWorkOrderInput,
  getDefaultValues,
  schema,
} from "DDD/action-objects/WorkOrderItemsUpdate";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { JobPhase, JobPhaseFile, ProposalProduct } from "generated/graphql";
import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import useGetJobPhaseCostItem from "hooks/jobs/job-phases/useGetJobPhaseCostItem";
import useUpdateWorkOrderItems from "hooks/jobs/job-phases/useUpdateWorkOrderItems";
import useProposalProductDetails from "hooks/products/useProposalProductDetails";
import { JobPhaseContext } from "modules/JobPhases/context/JobPhaseContext";
import JobDetails from "modules/jobs/WorkOrder/JobDetails";
import JobImages from "modules/jobs/WorkOrder/JobImages";
import JobNotes from "modules/jobs/WorkOrder/JobNotes";
import JobOverviewSection from "modules/jobs/WorkOrder/JobOverviewSection";
import JobPhaseScheduleSection from "modules/jobs/WorkOrder/JobPhaseScheduleSection";
import OrganizationAndForemanDetails from "modules/jobs/WorkOrder/OrganizationAndForemanDetails";
import ProductDetails from "modules/jobs/WorkOrder/ProductDetails";
import Title from "modules/jobs/WorkOrder/Title";
import TruckingSection from "modules/jobs/WorkOrder/TruckingSection";
import { ReactNode, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

interface Props {
  data?: JobPhase;
  isJob?: boolean;
  isModal?: boolean;
  children?: ReactNode;
}

export default function WorkOrderComponent({
  data,
  children,
  isJob = false,
  isModal = false,
}: Props) {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const navigate = useNavigate();
  const today = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
  const [date, setDate] = useState(today);
  const jobPhaseId = data?.id;
  const { requiresTrucking } = useProposalProductDetails(data?.proposalProduct as ProposalProduct);
  const { data: jobPhaseCostItem, error: errorJobPhaseCostItem } = useGetJobPhaseCostItem({
    date,
    jobPhaseId: data?.id,
  });

  const [updateWorkOrderItems, { isSuccess }] = useUpdateWorkOrderItems();
  const methods = useForm<UpdateWorkOrderInputSchema>({
    defaultValues: getDefaultValues({ jobPhase: data as CustomJobPhase }),
    resolver: zodResolver(schema),
  });
  const onWorkOrderSubmit = useCallback(async (values: UpdateWorkOrderInputSchema) => {
    const cleanedValues = cleanUpdateWorkOrderInput(values);
    await updateWorkOrderItems({
      variables: {
        input: {
          ...cleanedValues,
        },
      },
    });
  }, []);
  const images = data?.proposalProduct.files.filter((file) => file.type === "image");

  return (
    <JobPhaseContext.Provider value={{ jobPhase: data as CustomJobPhase, jobPhaseCostItem, date }}>
      <FormProvider {...methods}>
        <MDBox
          component="form"
          onSubmit={methods.handleSubmit(onWorkOrderSubmit)}
          id="work-order-print"
        >
          <MDBox
            bgColor="#f5f5f5"
            py={2}
            px={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h4">{`${data?.proposalStage?.name} - ${data?.organizationProductTypeName} - Work Order #${jobPhaseId}`}</MDTypography>
            {isModal && (
              <MDBox display="flex" justifyContent="flex-end" p={2} className="no-print">
                <MDButton
                  variant="contained"
                  color="blue"
                  onClick={() => {
                    window.open(
                      `/jobs/${data?.job?.id}/phases/${jobPhaseId}/work-order?autoPrint=true`,
                      "_blank"
                    );
                  }}
                >
                  <Print />
                </MDButton>
              </MDBox>
            )}
          </MDBox>

          {children}
          {data && (
            <>
              <MDBox my={2}>
                <Card>
                  <MDTypography></MDTypography>
                  <MDBox p={2}>
                    <MDBox pb={2} display="flex" justifyContent="center" alignItems="center">
                      <MDTypography variant="h4">{data?.organization?.name}</MDTypography>
                      {!isJob && !isModal && (
                        <MDBox display="flex" justifyContent="flex-end" p={2} className="no-print">
                          <MDButton variant="contained" color="blue" onClick={() => window.print()}>
                            <Print />
                          </MDButton>
                        </MDBox>
                      )}
                    </MDBox>
                    <Grid container spacing={2}>
                      <ProductDetails phase={data as JobPhase} />
                      <OrganizationAndForemanDetails
                        organization={data?.organization}
                        contact={data?.job?.contact}
                        foreman={data?.foremanUser}
                      />
                      <JobPhaseScheduleSection data={data as JobPhase} />
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>

              <JobNotes notes={data?.notes} jobPhaseId={data?.id} />
              <JobDetails jobPhase={data as JobPhase} />
              {
                //@ts-ignore
                (jobPhaseCostItem?.transferTrucks?.length > 0 ||
                  data?.proposalProduct?.transferTrucks?.length > 0 ||
                  data?.costItems?.length > 0 ||
                  requiresTrucking) && <TruckingSection data={data as JobPhase} />
              }

              <JobImages jobImages={data?.files as JobPhaseFile[]} />
              <JobOverviewSection jobId={data?.job?.id} jobPhaseId={data?.id} isJob={isJob} />
            </>
          )}
          <MDBox p={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
            <MDButton type="submit" color="success" className="no-print">
              {" "}
              Save Work Order
            </MDButton>
          </MDBox>
        </MDBox>
      </FormProvider>
    </JobPhaseContext.Provider>
  );
}
