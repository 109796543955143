import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import Card from "@mui/material/Card";

// Data
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";
import OrganizationUsersList from "modules/OrganizationUsersList/OrganizationUsersList";

export default function UsersPage() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Users
            </MDTypography>
            <MDTypography as={Link} to={getRoute("users.invite")}>
              <MDButton color="blue">
                <Icon>add</Icon>&nbsp; Invite User
              </MDButton>
            </MDTypography>
          </MDBox>
          <OrganizationUsersList />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
