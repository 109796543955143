import { Avatar, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { FileInput, useUppy } from "@uppy/react";
import MDTypography from "components/MDTypography";
import PreviewAvatar from "components/PreviewAvatar/PreviewAvatar";
import useGenerateUploadCompanyLogoUrl from "hooks/companies/useGenerateUploadCompanyUrl";
import React, { useCallback, useState } from "react";
import { DeepRequired, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import MDBox from "components/MDBox";

export default React.forwardRef(function LogoUpload(
  {
    label,
    watcher,
    error,
    sendPath,
    ...rest
  }: {
    label: string;
    watcher: any;
    sendPath?: (path: string) => void;
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<any>>>;
  },
  ref: any
) {
  const [generateUploadCompanyLogoUrl, { isSuccess, getData }] = useGenerateUploadCompanyLogoUrl();
  const [preview, setPreview] = useState("");
  const readImage = useCallback((file) => {
    const blob = new Blob([file.data], { type: file.type });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setPreview(reader.result.toString());
    };
  }, []);
  const uppy = useUppy(() => {
    const _uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: false,
      allowMultipleUploads: false,
      locale: {
        strings: {
          chooseFiles: label ?? "Upload Avatar",
        },
      },
      restrictions: {
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const result = await generateUploadCompanyLogoUrl({
          variables: {
            contentType: file.type,
            filename: file.name,
          },
        });
        const success = isSuccess(result.data);
        if (success) {
          const data = getData(result);
          if (sendPath) {
            sendPath(data.path);
          }
          return {
            method: data.method as "PUT",
            url: data.url,
            fields: {},
            headers: {
              "Content-Type": file.type,
            },
          };
        } else {
          throw new Error("Could not upload the file");
        }
      },
    });
    _uppy.on("upload-success", (file, response) => {
      if (response.status === 200) {
        readImage(file);
        _uppy.removeFile(file.id);
      }
    });
    return _uppy;
  });
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={2}>
        {watcher?.length > 0 || preview ? (
          <PreviewAvatar value={preview ? preview : watcher} isPreview={!!preview} />
        ) : (
          <Avatar src={null} />
        )}
      </Grid>
      <Grid item xs={10}>
        <FileInput uppy={uppy} inputName="file" pretty />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <MDTypography color="error" variant="caption">
            {error.message}
          </MDTypography>
        </Grid>
      )}
    </Grid>
  );
});
