import { Autocomplete, FormControl, TextField } from "@mui/material";

interface MultipleSelectOptions {
  label: string;
  value: string | number;
}
interface CustomMultipleSelectProps {
  fullWidth: boolean;
  value?: MultipleSelectOptions[];
  onChange: (value: MultipleSelectOptions[]) => void;
  label: string;
  name: string;
  error?: boolean;
  options: MultipleSelectOptions[];
}
export default function CustomMultipleSelect({
  fullWidth,
  value,
  onChange,
  label,
  error,
  name,
  options,
}: CustomMultipleSelectProps) {
  const selected = value;

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={
        selected
          ? {
              "&": {
                mt: "3px",
              },
            }
          : {
              "&": {
                py: "10px",
              },
            }
      }
    >
      <Autocomplete
        options={options}
        getOptionLabel={(option: MultipleSelectOptions) => option.label}
        multiple
        isOptionEqualToValue={(option, value) => option.label === value.label}
        value={value ?? []}
        onChange={(e, value) => onChange(value as MultipleSelectOptions[])}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} name={name} label={label} variant="standard" />
        )}
      />
    </FormControl>
  );
}
