import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { Opportunity, OpportunityStatus } from "generated/graphql";
import useCancelOpportunity from "hooks/opportunities/useCancelOpportunity";
import useDeleteOpportunity from "hooks/opportunities/useDeleteOpportunity";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export default function DeleteOpportunityMenuItem(props: BitumioMenuItem<Opportunity>) {
  const { closeMenu, object } = props;
  const [deleteOpportunity, { isSuccess }] = useDeleteOpportunity();

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const { data } = await deleteOpportunity({ variables: { id: object.id } });
    if (isSuccess(data)) {
      closeMenu();
      return true;
    } else {
      return false;
    }
  }, []);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Are you sure you want to continue?"
        content="This opportunity will be deleted. Are you sure you want to continue?"
        acceptText="Delete Opportunity"
      />
      <MenuItem onClick={() => setOpen(true)}>Delete</MenuItem>
    </>
  );
}
