import { Card, Grid } from "@mui/material";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/file-input/dist/style.css";
import { useUppy, FileInput } from "@uppy/react";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect/MDSelect";
import MDTypography from "components/MDTypography";
import PhoneInput from "components/PhoneInput/PhoneInput";
import { USTimezones } from "constants/USTimezones";
import { Organization, UpdateOrganizationInput } from "generated/graphql";
import useGenerateUploadOrganizationLogoUrl from "hooks/organization/useGenerateUploadOrganizationLogoUrl";
import useUpdateOrganization from "hooks/organization/useUpdateOrganization";
import { useCallback, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

export default function OrganizationInfo({ organization }: { organization: Organization }) {
  const { id, name, logo, phone, email, timezone } = organization;
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<UpdateOrganizationInput>({
    defaultValues: {
      id,
      name,
      phone,
      email,
      timezone,
    },
  });

  const [updateOrganization] = useUpdateOrganization();

  const [generateUploadOrganizationLogoUrl, { getData, isSuccess }] =
    useGenerateUploadOrganizationLogoUrl();

  const [preview, setPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [logoPath, setLogoPath] = useState<null | string>(null);
  const pathRef = useRef("");

  const onSuccess = useCallback(async () => {
    setLogoPath(pathRef.current);
  }, [organization.id]);

  const readImage = useCallback((file) => {
    const blob = new Blob([file.data], { type: file.type });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setPreview(reader.result.toString());
    };
  }, []);

  const onSubmit = (data) =>
    updateOrganization({
      variables: {
        input: {
          ...data,
          ...(logoPath && { logo: logoPath }),
        },
      },
    });

  const uppy = useUppy(() => {
    const _uppy = new Uppy({
      id: "organizationLogo",
      autoProceed: true,
      allowMultipleUploads: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      },
      locale: {
        strings: {
          chooseFiles: "Choose Logo",
        },
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const result = await generateUploadOrganizationLogoUrl({
          variables: {
            contentType: file.type,
            filename: file.name,
          },
        });
        const success = isSuccess(result.data);
        if (success) {
          const data = getData(result);
          pathRef.current = data.path;
          return {
            method: data.method as "PUT",
            url: data.url,
            fields: {},
            headers: {
              "Content-Type": file.type,
            },
          };
        } else {
          throw new Error("Could not upload logo");
        }
      },
    });

    _uppy.on("upload-success", (file, response) => {
      if (response.status === 200) {
        onSuccess();
        readImage(file);
        _uppy.removeFile(file.id);
      }
    });

    _uppy.on("restriction-failed", (file, error) => {
      if (error) {
        setErrorMessage(error.message);
      }
    });

    _uppy.on("upload-error", (file) => {
      if (file) {
        setErrorMessage("An error has ocurred while uploading this file");
        _uppy.removeFile(file.id);
      }
    });

    return _uppy;
  });

  return (
    <MDBox mb={4} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <MDBox padding={4}>
          <MDTypography variant="h5" fontWeight="medium" mb={2}>
            Organization Information
          </MDTypography>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} lg={6}>
              {(preview || logo) && (
                <MDBox
                  component="img"
                  src={preview || `${import.meta.env.VITE_CDN_BASE_URL}/${logo}`}
                  maxWidth={300}
                  alt="logo"
                />
              )}
              <FileInput uppy={uppy} pretty />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FormField
                label="Name"
                fullWidth
                margin="normal"
                {...register("name", { required: "Name required" })}
                error={errors.name}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field }) => <PhoneInput {...field} label="Phone" variant="outlined" />}
              />
              <FormField
                fullWidth
                label="Email"
                margin="normal"
                {...register("email", { required: "Email required" })}
                error={errors.email}
              />
              <Controller
                name="timezone"
                control={control}
                render={({ field }) => (
                  <MDSelect
                    {...field}
                    error={!!errors?.timezone?.message}
                    name="timezone"
                    value={field.value}
                    fullWidth
                    label="Select Timezone to display in emails"
                    options={USTimezones}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MDButton variant="contained" color="success" type="submit">
                Save
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}
