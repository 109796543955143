import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import MDAlert from "components/MDAlert";
import { Grid, Skeleton } from "@mui/material";
import useGetJobPhase from "hooks/jobs/job-phases/useGetJobPhase";
import View from "modules/JobPhases/View";
import Title from "modules/jobs/WorkOrder/Title";

export default function JobCostModal({ jobId, jobPhaseId }: { jobPhaseId: string; jobId: string }) {
  const { open, onClose, onOpen } = useModalState();
  const { loading, error, data: jobPhase } = useGetJobPhase({ id: jobPhaseId });

  return (
    <>
      <MDBox
        sx={{
          button: {
            padding: "6px",
            width: "120px",
          },
        }}
      >
        <MDButton type="button" color="blue" onClick={onOpen}>
          Input Job Cost
        </MDButton>
      </MDBox>
      <Modal open={open} onClose={onClose}>
        <Title
          text={`Job #${jobPhase?.externalId ?? ""} > ${
            jobPhase?.organizationProductTypeName ?? ""
          }`}
        />
        {loading && <>Loading</>}
        {error && (
          <MDAlert color="error">
            Could not load job phase material detail. Please refresh this page or try again later.
          </MDAlert>
        )}
        <MDBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!loading && jobPhase && <View jobPhase={jobPhase} addPhaseCostCompleted={onClose} />}
              {loading && <Skeleton />}
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
    </>
  );
}
