// Material Dashboard 2 PRO React TS components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { forwardRef } from "react";
import MDTypography from "components/MDTypography";
import { isNotNilOrEmpty } from "ramda-adjunct";
import { capitalCase } from "change-case";

// Declaring props types for FormField
interface Props {
  label: string;
  placeholder?: string;
  error?: { message: string };
  [key: string]: any;
  disabled?: boolean;
  shrink?: boolean;
  align?: string;
  valueAsNumber?: boolean;
}

const FormField = forwardRef((props: Props, ref) => {
  const {
    label,
    error,
    disabled,
    shrink,
    align = "left",
    placeholder,
    valueAsNumber = false,
    suffix = null,
    ...rest
  } = props;

  let formattedLabel = label
    ? capitalCase(label, {
        splitRegexp: /([a-z])([A-Z0-9])/g,
        stripRegexp: /[^A-Z0-9%.=\(\)\']/gi,
      })
    : "";

  if (suffix) {
    formattedLabel += ` (${suffix})`;
  }

  return (
    <MDBox width="100%">
      <MDInput
        {...rest}
        {...(valueAsNumber
          ? {
              onChange: (e) => rest.onChange(isNaN(+e.target.value) ? undefined : +e.target.value),
              value: `${
                rest.value
                  ? Number.isInteger(rest.value)
                    ? rest.value
                    : parseFloat(rest.value?.toFixed(2) ?? 0)
                  : ""
              }`,
            }
          : {})}
        sx={{
          ...(align === "right"
            ? {
                "& .MuiInputBase-root": {
                  "& input": {
                    textAlign: "right",
                  },
                },
              }
            : {}),
        }}
        inputProps={{
          ...rest.inputProps,
          enterKeyHint: "done",
          disabled,
        }}
        InputLabelProps={{
          ...rest.InputLabelProps,
          shrink:
            shrink ||
            (placeholder !== null && typeof placeholder !== "undefined" && placeholder !== "") ||
            (rest.value !== null && typeof rest.value !== "undefined" && rest.value !== "") ||
            undefined,
        }}
        placeholder={placeholder}
        disabled={disabled}
        ref={ref}
        variant="standard"
        label={formattedLabel}
        fullWidth
        error={!!error}
      />
      {error && (
        <MDTypography color="error" variant="caption">
          {error.message}
        </MDTypography>
      )}
    </MDBox>
  );
});

FormField.displayName = "FormField";

export default FormField;
