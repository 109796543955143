import { Icon } from "@mui/material";
import AuthWall from "modules/AuthWall/AuthWall";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";

import CompaniesPage from "pages/companies";
import CreateCompanyPage from "pages/companies/create";
import CompanyJobsCreatePage from "pages/companies/jobs/create";
import CompanyProposalsCreatePage from "pages/companies/proposals/create";
import UpdateCompanyPage from "pages/companies/update";
import ViewCompanyPage from "pages/companies/view";

const companyRoutes = [
  {
    type: "link",
    name: "Companies/Contacts",
    key: "companies.list",
    route: "/companies",
    icon: <Icon fontSize="medium">business</Icon>,
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <CompaniesPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Create Company",
    key: "companies.create",
    route: "/companies/create",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <CreateCompanyPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Create Proposal",
    key: "companies.proposals.create",
    route: "/companies/:companyId/proposals/create",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <CompanyProposalsCreatePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Create Job",
    key: "companies.jobs.create",
    route: "/companies/:companyId/jobs/create",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <CompanyJobsCreatePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "View",
    key: "companies.view",
    route: "/companies/:companyId",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <ViewCompanyPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Update",
    key: "companies.update",
    route: "/companies/:companyId/update",
    component: (
      <AuthWall>
        <AuthWallSubscriber>
          <UpdateCompanyPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
] as const;

export default companyRoutes;
