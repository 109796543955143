import { Grid } from "@mui/material";
import FormSkeleton from "components/FormSkeleton/FormSkeleton";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Product } from "generated/graphql";
import useDuplicateProduct from "hooks/products/useDuplicateProduct";
import { useGetProduct } from "hooks/products/useGetProduct";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import OrganizationProductTypeProductUpdate from "modules/products/OrganizationProductTypeProductUpdate";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getRoute } from "utils/routing";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import useConfirmationDialog from "hooks/useConfirmationDialog";

export default function OrganizationProductTypeProductUpdatePage() {
  const { productId, productTypeId } = useParams();
  const navigate = useNavigate();

  const { data: product, loading, error } = useGetProduct({ id: productId });

  const [duplicateProduct, { loading: duplicateLoading, isSuccess }] = useDuplicateProduct({
    organizationProductTypeId: productTypeId,
  });

  const handleDuplicateProduct = useCallback(async () => {
    const { data } = await duplicateProduct({
      variables: { id: productId },
    });
    const success = isSuccess(data);
    if (success) {
      navigate(
        getRoute("product-types.products.update", [
          ["productTypeId", productTypeId],
          ["productId", data.duplicateProduct.id],
        ])
      );
    }
    return success;
  }, []);

  const cancelFn = useCallback(async () => {
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn: handleDuplicateProduct,
    cancelFn,
  });

  return (
    <DashboardPage>
      {loading && <FormSkeleton rows={4} />}
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      {!loading && !error && (
        <>
          <MDBox mb={6}>
            <Grid container alignItems="center" justifyContent={"space-between"}>
              <MDTypography variant="h4" fontWeight="medium">
                Update Product - {product.name}
              </MDTypography>
              <MDButton color="blue" loading={duplicateLoading} onClick={() => setOpen(true)}>
                Duplicate Product
              </MDButton>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OrganizationProductTypeProductUpdate
                product={product as Product}
                productType={product.organizationProductType}
              />
            </Grid>
          </Grid>
          <ActionConfirmDialog
            open={open}
            setOpen={setOpen}
            handleAccept={onAccept}
            handleCancel={onCancel}
            title="Confirmation"
            content="Are you sure you would like to duplicte this product?"
          />
        </>
      )}
    </DashboardPage>
  );
}
