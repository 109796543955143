import { useGetOrganizationSettingsQuery } from "generated/graphql";
import { useMemo } from "react";

const useGetOrganizationSettings = () => {
  const { data: graphqlData, loading, error } = useGetOrganizationSettingsQuery();

  const data = useMemo(() => {
    if (!graphqlData) {
      return {};
    }

    return (graphqlData?.getOrganizationSettings.reduce((acc, val) => {
      return {
        ...acc,
        [val.key]: val.value,
      };
    }, {}) ?? {}) as Record<string, string>;
  }, [graphqlData]);

  return {
    data,
    loading,
    error,
  };
};

export default useGetOrganizationSettings;
