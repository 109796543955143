import { compose, converge, head, map, pick, tail, toPairs } from "ramda";
import { sentenceCase } from "change-case";
import { renameKeysWith } from "ramda-adjunct";

export const getCompanyInfo = compose(
  map(converge((label: string, value: string) => ({ label, value }), [head, tail])),
  toPairs,
  renameKeysWith(sentenceCase),
  pick([
    "name",
    "status",
    "phone",
    "email",
    "addressLine1",
    "addressLine2",
    "addressCity",
    "addressState",
    "addressZip",
    "addressCountry",
    "website",
    "notes",
    "proposalsCount",
    "jobsCount",
    "notificationTypes",
  ])
);
